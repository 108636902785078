var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-3 px-5" }, [
    _c("p", { staticClass: "header borderless backgroundless py-3 pl-0" }, [
      _vm._v("CUSTOM ATTRIBUTES")
    ]),
    _c("p", { staticClass: "subheader" }, [
      _vm._v(
        " This information is taken from your enterprise calendar. In order to edit this data please visit your enterprise calendar administration panel. "
      )
    ]),
    _c("div", { staticClass: "content w-100 d-flex justify-content-between" }, [
      _c(
        "div",
        { staticClass: "attributes-view" },
        [
          _vm.hasAttributes
            ? _vm._l(_vm.model, function(attribute) {
                return _c(
                  "div",
                  {
                    key: attribute.key,
                    staticClass: "d-flex py-2 align-items-start"
                  },
                  [
                    _c("div", { staticClass: "attribute-details pl-0" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v(_vm._s(attribute.key))
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(attribute.value))
                      ])
                    ])
                  ]
                )
              })
            : _vm._e(),
          !_vm.hasAttributes
            ? [
                _c(
                  "p",
                  { staticClass: "header borderless backgroundless pl-0" },
                  [_vm._v("No custom attributes to show.")]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c("img", {
        staticClass: "theme-image align-self-center",
        attrs: { src: require("@/assets/svg/organization-details.svg") }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }