var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticClass: "counter" }, [
    _vm._v(
      _vm._s(_vm.currentModel ? _vm.currentModel.length : 0) +
        " / " +
        _vm._s(_vm.maxNumberOfCharacters)
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }