var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("breadcrumb", {
        staticClass: "dashboard-page__breadcrumbs",
        attrs: { "breadcrumb-paths": _vm.getBreadcrumbs() }
      }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }