var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("img", {
      staticClass: "loading-icon",
      attrs: { src: require("@/assets/svg/loader.svg") }
    }),
    _vm.propsData && _vm.propsData.text
      ? _c("span", { staticClass: "loading-text" }, [
          _vm._v(_vm._s(_vm.propsData.text))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }