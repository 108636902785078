var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("breadcrumb", {
        attrs: {
          "breadcrumb-paths": [
            { name: "My Organization", path: "/dashboard" },
            { name: "Team", path: "" }
          ]
        }
      }),
      _c(
        "div",
        { staticClass: "dashboard-panel team-panel" },
        [
          _c("div", { staticClass: "header" }, [_vm._v("Your team")]),
          _c(
            "div",
            { staticClass: "content px-5 py-4 d-flex justify-content-between" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column team-content",
                  attrs: { "data-testid": "my-team-container" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid-header pb-3 d-flex justify-content-between align-items-center"
                    },
                    [
                      _c("h5", { staticClass: "m-0" }, [_vm._v("MEMBERS")]),
                      _c(
                        "button",
                        {
                          staticClass: "primary-button",
                          attrs: {
                            "data-testid": "add-team-member-button",
                            disabled: _vm.teamMembers.length === 21
                          },
                          on: { click: _vm.addMember }
                        },
                        [
                          _c("i", { staticClass: "fa fa-tag fa-fw" }),
                          _vm._v("Add new member ")
                        ]
                      )
                    ]
                  ),
                  _vm._l(_vm.teamMembers, function(teamMember) {
                    return _c(
                      "div",
                      {
                        key: teamMember.id,
                        staticClass:
                          "team-member-container d-flex justify-content-between align-items-center",
                        attrs: {
                          "data-testid":
                            "team-member-" + teamMember.surname + "-row"
                        }
                      },
                      [
                        _c("user-card", {
                          staticClass: "team-member-card",
                          attrs: {
                            user: teamMember,
                            "show-phone-number": true,
                            "details-width": 230,
                            "data-testid": "team-member-details"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "member-properties d-flex justify-content-between align-items-center",
                            attrs: { "data-testid": "team-member-properties" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "checkbox d-flex justify-content-between align-items-center",
                                attrs: { "data-testid": "primary-user-details" }
                              },
                              [
                                _c("checkbox", {
                                  staticClass: "checkbox-control",
                                  attrs: {
                                    model: teamMember.isPrimaryContact,
                                    "is-readonly": true
                                  },
                                  model: {
                                    value: teamMember.isPrimaryContact,
                                    callback: function($$v) {
                                      _vm.$set(
                                        teamMember,
                                        "isPrimaryContact",
                                        $$v
                                      )
                                    },
                                    expression: "teamMember.isPrimaryContact"
                                  }
                                }),
                                _c("label", [_vm._v("Primary User")])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "checkbox d-flex justify-content-between align-items-center",
                                attrs: {
                                  "data-testid": "invoice-receiver-details"
                                }
                              },
                              [
                                _c("checkbox", {
                                  staticClass: "checkbox-control",
                                  attrs: {
                                    model: teamMember.isInvoicesRecipient,
                                    "is-readonly": true
                                  },
                                  model: {
                                    value: teamMember.isInvoicesRecipient,
                                    callback: function($$v) {
                                      _vm.$set(
                                        teamMember,
                                        "isInvoicesRecipient",
                                        $$v
                                      )
                                    },
                                    expression: "teamMember.isInvoicesRecipient"
                                  }
                                }),
                                _c("label", [_vm._v("Invoice receiver")])
                              ],
                              1
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "edit-link",
                                attrs: {
                                  href: "#",
                                  "data-testid": "team-member-edit-button"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.editMember(teamMember, $event)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-pencil fa-fw" }),
                                _vm._v("edit ")
                              ]
                            ),
                            !teamMember.isPrimaryContact
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "edit-link",
                                    attrs: {
                                      href: "#",
                                      "data-testid": "team-member-delete-button"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onTeamMemberDelete(
                                          teamMember
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash fa-fw"
                                    }),
                                    _vm._v("delete ")
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm._m(0)
            ]
          ),
          _vm.isLoading
            ? _c("overlay-loader", { attrs: { "overlay-color": "#f5f6fa" } })
            : _vm._e()
        ],
        1
      ),
      _c("team-member-details-edit-modal", {
        attrs: {
          visible: _vm.isTeamMemberEditModalVisible,
          model: _vm.editedTeamMember
        },
        on: {
          cancel: _vm.onTeamMemberEditModalCancel,
          edited: function($event) {
            return _vm.onTeamMemberEditModalEdited($event)
          }
        }
      }),
      _c("team-member-details-add-modal", {
        attrs: { visible: _vm.isTeamMemberAddModalVisible },
        on: {
          cancel: _vm.onTeamMemberAddModalCancel,
          added: function($event) {
            return _vm.onTeamMemberAddModalAdded($event)
          }
        }
      }),
      _c("delete-member-confirmation-modal", {
        attrs: {
          visible: _vm.isDeleteTeamMemberConfirmationModalVisible,
          "confirm-button-label":
            _vm.deleteMemberConfirmationModalTitleConfirmButtonLabel,
          title: _vm.deleteMemberConfirmationModalTitle
        },
        on: {
          confirm: function($event) {
            return _vm.onDeleteConfirmed()
          },
          cancel: function($event) {
            return _vm.onDeleteCancelled()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "theme-image-container" }, [
      _c("img", {
        staticClass: "theme-image",
        attrs: { src: require("@/assets/svg/team.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }