var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "heading" }, [_vm._v("Credit Card status:")]),
    _vm.creditCardDetailsCompletionStatus !== null
      ? _c(
          "label",
          {
            staticClass: "credit-card-details-completion-status",
            class: _vm.statusClass
          },
          [_vm._v(_vm._s(_vm.getInfoMessage()))]
        )
      : _c(
          "div",
          { staticClass: "credit-card-details-completion-status__loader" },
          [
            _c("img", {
              attrs: { src: require("@/assets/svg/loader.svg"), alt: "loader" }
            }),
            _c("span", [_vm._v("Loading card status...")])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }