var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("breadcrumb", {
        attrs: {
          "breadcrumb-paths": [{ name: "Invoices and payments", path: "" }]
        }
      }),
      _c(
        "div",
        { staticClass: "dashboard-panel" },
        [
          _c("div", { staticClass: "header" }, [_vm._v("Recent invoices")]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "details-view" },
              [
                _c("invoices-details-grid", {
                  attrs: {
                    model: _vm.invoices,
                    "current-page": _vm.currentPageNumber
                  }
                })
              ],
              1
            )
          ]),
          _vm.isLoading
            ? _c("overlay-loader", { attrs: { "overlay-color": "#f5f6fa" } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }