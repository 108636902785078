var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pager d-flex justify-content-between" },
    [
      _vm.isResultEmpty
        ? [
            _c(
              "div",
              {
                staticClass: "pager-summary",
                attrs: { "data-testid": "resource-counter" }
              },
              [_vm._v("No results found.")]
            )
          ]
        : _vm._e(),
      !_vm.isResultEmpty
        ? [
            _c("div", { staticClass: "pager-summary" }, [
              _vm._v(
                "Showing " +
                  _vm._s(_vm.currentPageRange) +
                  " of " +
                  _vm._s(_vm.totalItemsCount)
              )
            ]),
            _c("div", { staticClass: "pager-buttons" }, [
              _c(
                "div",
                {
                  staticClass: "pager-button left-button unselectable",
                  on: { click: _vm.onPreviousClicked }
                },
                [_vm._v("<")]
              ),
              _c(
                "div",
                {
                  staticClass: "pager-button right-button unselectable",
                  on: { click: _vm.onNextClicked }
                },
                [_vm._v(">")]
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }