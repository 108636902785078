var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "table-responsive grid-container m-0" }, [
      _vm.model
        ? _c("table", { staticClass: "table table-borderless invoices-grid" }, [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(_vm.model, function(item) {
                  return [
                    _c("tr", { key: item.id }, [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.itemIndex) +
                            "." +
                            _vm._s(item.index) +
                            " " +
                            _vm._s(item.title)
                        )
                      ]),
                      _c("td", [_vm._v(_vm._s(item.quantity))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(item.unitPrice) + " " + _vm._s(_vm.currency)
                        )
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(item.totalPrice) + " " + _vm._s(_vm.currency)
                        )
                      ])
                    ])
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-calendar fa-fw mr-2" }),
          _vm._v(" ITEMS ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-file fa-fw mr-2" }),
          _vm._v(" QUANTITY ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-money fa-fw mr-2" }),
          _vm._v(" COST ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-history fa-fw mr-2" }),
          _vm._v(" TOTAL ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }