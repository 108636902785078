var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.config
    ? _c(
        "div",
        {
          staticClass: "preview-container",
          class: { active: _vm.isActive },
          on: { click: _vm.onClick }
        },
        [
          _c("img", {
            staticClass: "template",
            attrs: { src: _vm.config.previewImage }
          }),
          _c("img", {
            staticClass: "crop",
            style: {
              left: _vm.config.previewConfig.x + "px",
              top: _vm.config.previewConfig.y + "px"
            },
            attrs: {
              src: _vm.config.cropImage,
              width: _vm.config.previewConfig.w
            }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }