var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isInitialized
    ? _c(
        "div",
        { staticClass: "photo-picker d-flex justify-content-between" },
        [
          _vm.isNoThumbnailMode
            ? _c("div", { staticClass: "no-thumbnail-button__container" }, [
                _c(
                  "button",
                  {
                    staticClass: "secondary-button no-thumbnail-button",
                    on: { click: _vm.onAddPhotoClicked }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/svg/upload.svg"),
                        alt: "upload"
                      }
                    }),
                    _vm._v(" Change photo ")
                  ]
                )
              ])
            : _c(
                "ul",
                {
                  staticClass: "photos-list d-flex justify-content-start",
                  attrs: { "data-testid": "added-photos-list" }
                },
                [
                  !!_vm.uploadedPhoto
                    ? _c("li", { on: { click: _vm.onUploadedImageChosen } }, [
                        _c(
                          "div",
                          {
                            staticClass: "image-item",
                            class: { active: !_vm.isDefaultImageChosen },
                            attrs: { "data-testid": "uploaded-photo" }
                          },
                          [_c("img", { attrs: { src: _vm.uploadedPhoto } })]
                        )
                      ])
                    : _vm._e(),
                  _c("li", { on: { click: _vm.onDefaultImageChosen } }, [
                    _c(
                      "div",
                      {
                        staticClass: "image-item",
                        class: { active: _vm.isDefaultImageChosen },
                        attrs: { "data-testid": "default-photo" }
                      },
                      [_c("img", { attrs: { src: _vm.defaultImage } })]
                    )
                  ]),
                  !_vm.uploadedPhoto
                    ? _c("li", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-photo-button d-flex justify-content-center align-items-center",
                            attrs: { "data-testid": "add-photo-button" },
                            on: { click: _vm.onAddPhotoClicked }
                          },
                          [
                            _c("span", { staticClass: "icon" }, [_vm._v("+")]),
                            _c("span", { staticClass: "label" }, [
                              _vm._v("Add photo")
                            ])
                          ]
                        )
                      ])
                    : _vm._e()
                ]
              ),
          _c("resource-photo-upload-modal", {
            attrs: {
              visible: _vm.isAddPhotoModalVisible,
              "is-file-cleared$": _vm.isFileCleared$
            },
            on: {
              cancel: _vm.onAddPhotoModalCancel,
              uploaded: function($event) {
                return _vm.onPhotoUploaded($event)
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }