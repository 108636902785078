var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("breadcrumb", {
        attrs: { "breadcrumb-paths": [{ name: "Instructions", path: "" }] }
      }),
      _c("div", { staticClass: "dashboard-panel" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v("Instructions and guidance")
        ]),
        _c("div", { staticClass: "instructions-container" }, [
          _c("div", { staticClass: "left-side-container" }, [
            _c("p", [_vm._v("DOCUMENTS TO DOWNLOAD:")]),
            _c("div", { staticClass: "single-element" }, [
              _c("div", { staticClass: "number" }, [_vm._v("1")]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Click to download",
                        classes: ["narrow-tooltip"]
                      },
                      expression:
                        "{ content: 'Click to download', classes: ['narrow-tooltip'] }"
                    }
                  ],
                  staticClass: "title",
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf(
                        "Bookado Admin Portal - Onboarding.pdf"
                      )
                    }
                  }
                },
                [_vm._v("Onboarding Guide")]
              ),
              _c("div", { staticClass: "description" }, [
                _vm._v("Onboarding steps description")
              ]),
              _c("div", { staticClass: "icon" }, [
                _c("img", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Click to download",
                        classes: ["narrow-tooltip"]
                      },
                      expression:
                        "{ content: 'Click to download', classes: ['narrow-tooltip'] }"
                    }
                  ],
                  staticClass: "pdf-icon",
                  attrs: {
                    src: require("../../assets/img/pdficon.png"),
                    alt: "instuctionimage"
                  },
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf(
                        "Bookado Admin Portal - Onboarding.pdf"
                      )
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "single-element" }, [
              _c("div", { staticClass: "number" }, [_vm._v("2")]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Click to download",
                        classes: ["narrow-tooltip"]
                      },
                      expression:
                        "{ content: 'Click to download', classes: ['narrow-tooltip'] }"
                    }
                  ],
                  staticClass: "title",
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf(
                        "Bookado O365 - Creating resources.pdf"
                      )
                    }
                  }
                },
                [_vm._v("Creating resources")]
              ),
              _vm._m(0),
              _c("div", { staticClass: "icon" }, [
                _c("img", {
                  staticStyle: { "margin-right": "20px" },
                  attrs: {
                    src: require("../../assets/img/ms-logo.png"),
                    alt: "instuctionimage"
                  }
                }),
                _c("img", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Click to download",
                        classes: ["narrow-tooltip"]
                      },
                      expression:
                        "{ content: 'Click to download', classes: ['narrow-tooltip'] }"
                    }
                  ],
                  staticClass: "pdf-icon",
                  attrs: {
                    src: require("../../assets/img/pdficon.png"),
                    alt: "instuctionimage"
                  },
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf(
                        "Bookado O365 - Creating resources.pdf"
                      )
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "single-element" }, [
              _c("div", { staticClass: "number" }, [_vm._v("3")]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Click to download",
                        classes: ["narrow-tooltip"]
                      },
                      expression:
                        "{ content: 'Click to download', classes: ['narrow-tooltip'] }"
                    }
                  ],
                  staticClass: "title",
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf(
                        "Bookado Admin Portal - Resource Description & Photo.pdf"
                      )
                    }
                  }
                },
                [_vm._v(" Resource"), _c("br"), _vm._v(" Description & Photo ")]
              ),
              _vm._m(1),
              _c("div", { staticClass: "icon" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/img/bookadoicon.png"),
                    alt: "instuctionimage"
                  }
                }),
                _c("img", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Click to download",
                        classes: ["narrow-tooltip"]
                      },
                      expression:
                        "{ content: 'Click to download', classes: ['narrow-tooltip'] }"
                    }
                  ],
                  staticClass: "pdf-icon",
                  attrs: {
                    src: require("../../assets/img/pdficon.png"),
                    alt: "instuctionimage"
                  },
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf(
                        "Bookado Admin Portal - Resource Description & Photo.pdf"
                      )
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "single-element" }, [
              _c("div", { staticClass: "number" }, [_vm._v("4")]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Click to download",
                        classes: ["narrow-tooltip"]
                      },
                      expression:
                        "{ content: 'Click to download', classes: ['narrow-tooltip'] }"
                    }
                  ],
                  staticClass: "title",
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf(
                        "Bookado Admin Portal - Generating markers.pdf"
                      )
                    }
                  }
                },
                [_vm._v("Generating Markers")]
              ),
              _vm._m(2),
              _c("div", { staticClass: "icon" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/img/bookadoicon.png"),
                    alt: "instuctionimage"
                  }
                }),
                _c("img", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: "Click to download",
                        classes: ["narrow-tooltip"]
                      },
                      expression:
                        "{ content: 'Click to download', classes: ['narrow-tooltip'] }"
                    }
                  ],
                  staticClass: "pdf-icon",
                  attrs: {
                    src: require("../../assets/img/pdficon.png"),
                    alt: "instuctionimage"
                  },
                  on: {
                    click: function($event) {
                      return _vm.downloadPdf(
                        "Bookado Admin Portal - Generating markers.pdf"
                      )
                    }
                  }
                })
              ])
            ])
          ]),
          _vm._m(3)
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _vm._v(" Create rooms and desks, fill-in all needed"),
      _c("br"),
      _vm._v(" fields to allow Bookado work efficiently. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _vm._v(" Add a photo and description to your"),
      _c("br"),
      _vm._v(" resources so users could better check if the"),
      _c("br"),
      _vm._v(" resource user is looking at is exactly what"),
      _c("br"),
      _vm._v(" he/she is looking for. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _vm._v(" Generate markers for best user experience"),
      _c("br"),
      _vm._v(" with Bookado ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-side-container" }, [
      _c("p", [
        _vm._v("PLEASE HELP US TO SAVE TREES, PRINT ONLY WHEN NECESSARY.")
      ]),
      _c("div", { staticClass: "image-description-container" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/img/instructionList.png"),
            alt: "instuctionlist"
          }
        }),
        _c("div", { staticClass: "description" }, [
          _vm._v(" We have created for you a set of instructions covering"),
          _c("br"),
          _vm._v(" the most important steps needed to configure Bookado"),
          _c("br"),
          _vm._v(" to work efficiently with your Calendar system. ")
        ])
      ]),
      _c("img", {
        attrs: {
          src: require("../../assets/img/instructionImage.png"),
          alt: "instuctionimage"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }