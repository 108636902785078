var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "checkbox-container", class: { readonly: _vm.isReadonly } },
    [
      _vm._v(" " + _vm._s(_vm.label) + " "),
      _vm.isRequired
        ? _c("span", { staticClass: "star" }, [_vm._v("*")])
        : _vm._e(),
      _vm.autocomplete
        ? _c("input", {
            attrs: {
              autocomplete: _vm.autocomplete,
              type: "checkbox",
              disabled: _vm.isReadonly
            },
            domProps: { checked: _vm.model },
            on: {
              input: function($event) {
                return _vm.onInput($event)
              }
            }
          })
        : _vm._e(),
      !_vm.autocomplete
        ? _c("input", {
            attrs: { type: "checkbox", disabled: _vm.isReadonly },
            domProps: { checked: _vm.model },
            on: {
              input: function($event) {
                return _vm.onInput($event)
              }
            }
          })
        : _vm._e(),
      _c("span", { staticClass: "checkmark" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }