var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "login-button",
      class: { disabled: !_vm.enabled },
      attrs: { id: _vm.id },
      on: { click: _vm.onClick }
    },
    [
      _vm._t("icon"),
      _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      _c("div", { staticClass: "placeholder" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }