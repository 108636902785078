var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("breadcrumb", {
        attrs: {
          "breadcrumb-paths": [
            { name: "My Organization", path: "/dashboard" },
            { name: "Subscription", path: "" }
          ]
        }
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-start" },
        [
          _c("div", { staticClass: "dashboard-panel panel-left" }, [
            _c("div", { staticClass: "header subscription" }, [
              _c("span", [_vm._v(" Subscription ")]),
              _vm.editPlan &&
              (_vm.subscriptionDetails.subscriptionStatus ===
                _vm.SubscriptionStatus.FreeTrial ||
                _vm.subscriptionDetails.subscriptionStatus ===
                  _vm.SubscriptionStatus.FreeTrialFinished)
                ? _c(
                    "button",
                    {
                      staticClass:
                        "primary-button small set-subscription-active-status",
                      attrs: { "data-testid": "active-payment-service-button" },
                      on: { click: _vm.onActivateSubscription }
                    },
                    [_vm._v(" Activate Paid Service ")]
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "details-view" }, [
                _c("div", { staticClass: "details-row" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "values-column" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "values-column__active-status",
                          attrs: { "data-testid": "organization-status" }
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.SubscriptionStatusLabels[
                                  _vm.subscriptionDetails.subscriptionStatus
                                ] || "unknown"
                              )
                            )
                          ]),
                          _vm.editPlan &&
                          (_vm.subscriptionDetails.subscriptionStatus ===
                            _vm.SubscriptionStatus.FreeTrial ||
                            _vm.subscriptionDetails.subscriptionStatus ===
                              _vm.SubscriptionStatus.FreeTrialFinished ||
                            _vm.subscriptionDetails.subscriptionStatus ===
                              _vm.SubscriptionStatus.Active ||
                            _vm.subscriptionDetails.subscriptionStatus ===
                              _vm.SubscriptionStatus.Renew)
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "text-button small deactivate-subscription",
                                  attrs: {
                                    "data-testid":
                                      "deactivate-subscription-button"
                                  },
                                  on: { click: _vm.onDeactivateSubscription }
                                },
                                [_vm._v(" Deactivate ")]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "p",
                        { attrs: { "data-testid": "subscription-start-date" } },
                        [_vm._v(_vm._s(_vm.subscriptionDetails.startDate))]
                      ),
                      _c(
                        "p",
                        { attrs: { "data-testid": "subscription-end-date" } },
                        [_vm._v(_vm._s(_vm.subscriptionDetails.endDate))]
                      ),
                      _c(
                        "p",
                        { attrs: { "data-testid": "subscription-type" } },
                        [_vm._v(_vm._s(_vm.subscriptionTypeLabel))]
                      ),
                      _vm.editPlan
                        ? _c("payment-method-selector", {
                            attrs: {
                              "is-wide": false,
                              "data-testid":
                                "subscription-payment-type-dropdown"
                            },
                            model: {
                              value: _vm.subscriptionDetails.paymentMethod,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.subscriptionDetails,
                                  "paymentMethod",
                                  $$v
                                )
                              },
                              expression: "subscriptionDetails.paymentMethod"
                            }
                          })
                        : _c(
                            "p",
                            {
                              attrs: {
                                "data-testid": "subscription-payment-type"
                              }
                            },
                            [_vm._v(_vm._s(_vm.paymentMethodLabel))]
                          ),
                      _c(
                        "p",
                        {
                          attrs: { "data-testid": "subscription-auto-renewal" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.subscriptionDetails.autoRenewalStatus)
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "splitter" }),
                _vm.editPlan && _vm.showEditCreditCardDetailsSection
                  ? _c(
                      "div",
                      { staticClass: "edit-credit-card-details" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("EDIT CREDIT CARD DETAILS:")
                        ]),
                        _c("div", { staticClass: "paragraph" }, [
                          _vm._v(
                            "Security and safety of your payments is our priority."
                          )
                        ]),
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(
                            "Credit Card information as well as payments are handled "
                          ),
                          _c("br"),
                          _vm._v("by our trusted partner - "),
                          _c(
                            "a",
                            {
                              attrs: { target: "_blank", href: _vm.stripeUrl }
                            },
                            [_vm._v("Stripe")]
                          ),
                          _vm._v(". You will be redirected "),
                          _c("br"),
                          _vm._v(
                            "to a partner site to enter or edit your Credit Card details."
                          )
                        ]),
                        _c("p", { staticClass: "desc my-2" }, [
                          _vm._v("Credit Card payment confirmation receiver:")
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex flex-nowrap ml-3 w-100" },
                          [
                            _c(
                              "label",
                              { staticClass: "desc mr-2 text-black-50" },
                              [_vm._v("Sent to:")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.paymentConfirmationReceiverEmail,
                                  expression: "paymentConfirmationReceiverEmail"
                                }
                              ],
                              staticClass:
                                "font-weight-light payment-confirmation-email",
                              attrs: {
                                name: "payment-confirmation-receiver-email",
                                type: "email"
                              },
                              domProps: {
                                value: _vm.paymentConfirmationReceiverEmail
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.paymentConfirmationReceiverEmail =
                                    $event.target.value
                                }
                              }
                            })
                          ]
                        ),
                        !_vm.isLoadingCardStatus
                          ? _c("stripe-credit-card-details-completion-status", {
                              attrs: {
                                status: _vm.setupIntentStatus,
                                "does-customer-have-any-card": !!_vm.cardLast4Digits
                              }
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "stripe-payment-details__container",
                            class: { "no-card": !_vm.cardLast4Digits }
                          },
                          [
                            _c(
                              "div",
                              {
                                class:
                                  "payment-provider__container " +
                                  (_vm.cardLast4Digits ? "" : "no-card")
                              },
                              [
                                _c("stripe-payment-provider-checkout", {
                                  attrs: {
                                    "stripe-customer-id":
                                      _vm.subscriptionDetails.stripeCustomerId,
                                    "stripe-checkout-context":
                                      _vm.stripeCheckoutContext,
                                    label: _vm.getEditCreditCardLabel()
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.cardLast4Digits
                              ? _c("card-details", {
                                  attrs: {
                                    "last4-digits": _vm.cardLast4Digits,
                                    "expiration-date": _vm.cardExpirationDate,
                                    "payment-processing-network":
                                      _vm.cardPaymentProcessingNetwork,
                                    "billing-name": _vm.cardBillingName
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editPlan
                  ? _c("div", { staticClass: "edit-plan-container d-flex" }, [
                      _c("div", { staticClass: "cancel-save" }, [
                        _c(
                          "button",
                          {
                            staticClass: "secondary-button",
                            attrs: { "data-testid": "edit-plan-cancel-button" },
                            on: { click: _vm.onCancel }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "secondary-button",
                            attrs: { "data-testid": "edit-plan-save-button" },
                            on: { click: _vm.onSave }
                          },
                          [_vm._v("Save")]
                        )
                      ])
                    ])
                  : _c(
                      "div",
                      { staticClass: "p-3 d-flex justify-content-center" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "secondary-button",
                            attrs: {
                              "data-testid": "manage-subscription-button"
                            },
                            on: {
                              click: function($event) {
                                _vm.editPlan = true
                              }
                            }
                          },
                          [_vm._v(" Manage ")]
                        )
                      ]
                    )
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "dashboard-panel flex-grow-1 panel-right" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header d-flex justify-content-between align-items-center"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "plan-name",
                      attrs: { "data-testid": "chosen-subscription-type-label" }
                    },
                    [_vm._v(_vm._s(_vm.subscriptionDetails.planName))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "plan-switch-container d-flex justify-content-between align-items-center mr-4"
                    },
                    [
                      _c("i", {
                        staticClass: "fa icon fa-exclamation-circle fa-fw mx-3"
                      }),
                      _c("span", { staticClass: "mr-4" }, [
                        _vm._v(
                          "Please remember that downgrading plan will take effect next month, upgrading - immediately"
                        )
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "primary-button plan-button",
                          attrs: {
                            "data-testid": "subscription-change-plan-button"
                          },
                          on: {
                            click: function($event) {
                              _vm.isSubscriptionPlansModalVisible = true
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-tag fa-fw" }),
                          _vm._v(" Change plan ")
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "details-view" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "plan-summary p-3",
                      attrs: { "data-testid": "summary-plan-container" }
                    },
                    [
                      _c("li", { staticClass: "coin-item" }, [
                        _vm._v(" Features: "),
                        _c(
                          "ul",
                          { staticClass: "features" },
                          _vm._l(_vm.subscriptionDetails.features, function(
                            feature
                          ) {
                            return _c("li", { key: feature }, [
                              _vm._v(_vm._s(feature))
                            ])
                          }),
                          0
                        )
                      ]),
                      _c("li", { staticClass: "coin-item" }, [
                        _vm._v(" Cost calculation per your configuration ")
                      ]),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "feature-enabled",
                              rawName: "v-feature-enabled",
                              value:
                                _vm.Features.ResourcesCostCalculationFeature,
                              expression:
                                "Features.ResourcesCostCalculationFeature"
                            }
                          ],
                          staticClass: "coin-item"
                        },
                        [_vm._v("Cost calculation per your configuration")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "feature-enabled",
                          rawName: "v-feature-enabled",
                          value: _vm.Features.ResourcesCostCalculationFeature,
                          expression: "Features.ResourcesCostCalculationFeature"
                        }
                      ]
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "resources-header mx-4 px-4 py-2 d-flex justify-content-between align-items-center"
                        },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "Bookado " +
                                _vm._s(_vm.subscriptionDetails.planName) +
                                " (monthly subscription)"
                            )
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "secondary-button resources-button",
                              attrs: { to: { name: "resources" } }
                            },
                            [_vm._v("Manage your resources")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "px-4" },
                        [_c("subscription-resources-grid")],
                        1
                      )
                    ]
                  )
                ])
              ])
            ]
          ),
          _vm.isLoading
            ? _c("overlay-loader", { attrs: { "overlay-color": "#f5f6fa" } })
            : _vm._e()
        ],
        1
      ),
      _vm.isSubscriptionPlansModalVisible
        ? _c("subscription-plans-modal", {
            attrs: { visible: _vm.isSubscriptionPlansModalVisible },
            on: {
              cancel: _vm.onSubscriptionPlansModalCancel,
              accept: _vm.onSubscriptionPlansModalAccept
            }
          })
        : _vm._e(),
      _c("subscription-change-status-modal", {
        attrs: {
          "modal-labels": _vm.subscriptionChangeStatusModalLabels.activate,
          "on-confirm-fn": _vm.onConfirmActivatePaidService,
          "is-successfully-submitted": _vm.activateIsSuccessfullySubmitted
        },
        model: {
          value: _vm.activateSubscriptionModalViewModel,
          callback: function($$v) {
            _vm.activateSubscriptionModalViewModel = $$v
          },
          expression: "activateSubscriptionModalViewModel"
        }
      }),
      _c("subscription-change-status-modal", {
        attrs: {
          "modal-labels": _vm.subscriptionChangeStatusModalLabels.deactivate,
          "on-confirm-fn": _vm.onConfirmDeactivateSubscription,
          "additional-element":
            _vm.subscriptionDeactivationReasonSelectorComponent,
          "is-successfully-submitted": _vm.deactivateIsSuccessfullySubmitted
        },
        model: {
          value: _vm.deactivateSubscriptionModalViewModel,
          callback: function($$v) {
            _vm.deactivateSubscriptionModalViewModel = $$v
          },
          expression: "deactivateSubscriptionModalViewModel"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "names-column" }, [
      _c("p", [_vm._v("STATUS:")]),
      _c("p", [_vm._v("FROM:")]),
      _c("p", [_vm._v("TO:")]),
      _c("p", [_vm._v("SUBSCRIPTION TYPE:")]),
      _c("p", [_vm._v("PAYMENT METHOD:")]),
      _c("p", [_vm._v("AUTO RENEWAL:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }