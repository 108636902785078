var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("back-button", { attrs: { path: _vm.backButtonPath } }),
      _c("breadcrumb", { attrs: { "breadcrumb-paths": _vm.breadcrumbPaths } }),
      _c("previous-next-resource-details-buttons", {
        attrs: { "previous-id": _vm.previousId, "next-id": _vm.nextId }
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-start" },
        [
          _c("div", { staticClass: "dashboard-panel w-100" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "details-view" }, [
                _c(
                  "ul",
                  { staticClass: "nav nav-tabs" },
                  _vm._l(_vm.tabLabels, function(label, index) {
                    return _c(
                      "li",
                      {
                        key: label,
                        staticClass: "nav-item",
                        attrs: {
                          "data-testid": (label + "-tab-button").toLowerCase()
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: { active: _vm.currentTabIndex === index },
                            on: {
                              click: function($event) {
                                return _vm.onTabChanged(index)
                              }
                            }
                          },
                          [_vm._v(_vm._s(label))]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "toasts-container" },
                  [
                    _c("toast", {
                      attrs: { messages: _vm.toastMessagesSubject }
                    })
                  ],
                  1
                ),
                !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "tab-content clearfix" },
                      [
                        _vm.currentTabIndex === 0
                          ? _c("resource-photo", {
                              attrs: {
                                model: _vm.resourceImages,
                                "is-default-image-active":
                                  _vm.isDefaultImageActive,
                                "initial-is-default-image-active":
                                  _vm.initialIsDefaultImageActive,
                                "resource-type": _vm.resourceType
                              },
                              on: {
                                defaultImageSelected: function($event) {
                                  _vm.isDefaultImageActive = $event
                                },
                                saveImage: _vm.onSaveImage
                              }
                            })
                          : _vm._e(),
                        _vm.currentTabIndex === 1
                          ? _c("resource-description", {
                              attrs: {
                                model: _vm.resourceImages,
                                "is-default-image-active":
                                  _vm.isDefaultImageActive
                              },
                              on: {
                                defaultImageSelected: function($event) {
                                  _vm.isDefaultImageActive = $event
                                },
                                submit: _vm.onSaveDescription
                              }
                            })
                          : _vm._e(),
                        _vm.currentTabIndex === 2
                          ? _c("resource-custom-attributes", {
                              attrs: { model: _vm.resource }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ]),
          _vm.isLoading
            ? _c("overlay-loader", { attrs: { "overlay-color": "#f5f6fa" } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }