var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("back-button", { attrs: { path: _vm.backButtonPath } }),
      _c("breadcrumb", { attrs: { "breadcrumb-paths": _vm.breadcrumbPaths } }),
      _vm.invoice
        ? [
            _c(
              "div",
              {
                staticClass:
                  "invoice-header d-flex justify-content-between align-items-center"
              },
              [
                _c(
                  "div",
                  { staticClass: "properties d-flex justify-content-between" },
                  [
                    _c(
                      "div",
                      { staticClass: "property d-flex align-items-start" },
                      [
                        _c("i", { staticClass: "fa fa-file fa-fw mr-2" }),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("INVOICE NUMBER:")
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.invoice.invoiceId))
                          ])
                        ])
                      ]
                    ),
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "property d-flex align-items-start" },
                      [
                        _c("i", { staticClass: "fa fa-tag fa-fw mr-2" }),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("PLAN:")
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.invoice.planName))
                          ])
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "primary-button small download-button",
                    on: { click: _vm.onInvoiceDownload }
                  },
                  [
                    _c("i", { staticClass: "fa fa-download fa-fw mr-2" }),
                    _vm._v("Download invoice ")
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "invoice-details-container" }, [
              _c(
                "div",
                { staticClass: "invoice-details" },
                [
                  _vm._l(_vm.invoice.invoiceItems, function(item) {
                    return _c(
                      "div",
                      { key: item.index, staticClass: "invoice-item" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header d-flex justify-content-between px-3 py-2"
                          },
                          [
                            _c("div", { staticClass: "header-part" }, [
                              _vm._v(
                                _vm._s(item.index) + " " + _vm._s(item.title)
                              )
                            ]),
                            _c("div", { staticClass: "header-part" }, [
                              _vm._v(
                                _vm._s(item.totalPrice) +
                                  " " +
                                  _vm._s(_vm.invoice.currency)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "positions-container" },
                          [
                            _c("invoice-positions-grid", {
                              staticClass: "positions-grid",
                              attrs: {
                                model: item.positions,
                                currency: _vm.invoice.currency,
                                "item-index": item.index
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end invoice-summary-container"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between invoice-summary"
                        },
                        [
                          _c("div", { staticClass: "summary-item" }, [
                            _vm._v("Total Price")
                          ]),
                          _c("div", { staticClass: "d-block" }),
                          _c("div", { staticClass: "summary-item" }, [
                            _vm._v(
                              _vm._s(_vm.invoice.totalPrice) +
                                " " +
                                _vm._s(_vm.invoice.currency)
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ],
                2
              )
            ])
          ]
        : _vm._e(),
      _vm.isLoading
        ? _c("overlay-loader", { attrs: { "overlay-color": "#f5f6fa" } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "property d-flex align-items-start" }, [
      _c("i", { staticClass: "fa fa-history fa-fw mr-2" }),
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("span", { staticClass: "title" }, [_vm._v("SUBSCRIPTION TYPE:")]),
        _c("span", { staticClass: "value" }, [_vm._v("MONTHLY")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }