var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("wizard-header", { attrs: { "selected-step": 5 } }),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "plans-container d-flex justify-content-center" },
        [
          _vm._l(_vm.plans, function(plan) {
            return _c(
              "div",
              {
                key: plan.id,
                staticClass: "plan-container ml-3 mr-3",
                class: { selected: plan.id === _vm.selectedPlanId }
              },
              [
                _c("wizard-subscription-plan", {
                  attrs: { plan: plan },
                  on: {
                    select: function($event) {
                      return _vm.onPlanSelected(plan)
                    }
                  }
                })
              ],
              1
            )
          }),
          _vm.isLoadingPlans || _vm.isLoadingSelectedPlan
            ? _c("overlay-loader")
            : _vm._e()
        ],
        2
      ),
      _c("div", { staticClass: "step5-footer" }, [
        _c("p", { staticClass: "prices-notice" }, [
          _vm._v(
            "All prices are per room (or desk) per month, excluding VAT. You can upgrade or downgrade plan at any time. You can unsubscribe at any time."
          )
        ]),
        _c("p", { staticClass: "trial-notice" }, [
          _vm._v(
            "After one month of FREE TRIAL your monthly payment depends on a number of resources in your organization"
          )
        ]),
        _c(
          "div",
          { staticClass: "navigation d-flex justify-content-between" },
          [
            _c(
              "router-link",
              {
                staticClass: "back-link-button align-self-center",
                attrs: { to: { name: "step4" } }
              },
              [_vm._v("< Back")]
            ),
            _c(
              "button",
              {
                staticClass: "primary-button",
                attrs: {
                  id: "onboarding-step-5-continue",
                  disabled:
                    !_vm.selectedPlanId || _vm.selectedPlanId.length === 0
                },
                on: { click: _vm.onAccept }
              },
              [_vm._v("Continue")]
            )
          ],
          1
        )
      ]),
      _c("document-footer", { staticClass: "pt-4 pb-2" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header step5-header dice-header mb-5" }, [
      _c("h3", [_vm._v("Choose your plan")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }