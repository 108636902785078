var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "previous-next-navigator" }, [
    _c("div", { staticClass: "button-container previous-button" }, [
      _vm.previousId
        ? _c("a", { attrs: { href: _vm.previousId } }, [
            _c("img", {
              attrs: { src: require("@/assets/svg/previous-arrow.svg") }
            }),
            _vm._v(" previous ")
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "button-container next-button" }, [
      _vm.nextId
        ? _c("a", { attrs: { href: _vm.nextId } }, [
            _vm._v(" next "),
            _c("img", {
              attrs: { src: require("@/assets/svg/next-arrow.svg") }
            })
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }