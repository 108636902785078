var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-loader-overlay",
      style: { backgroundColor: _vm.overlayColor },
      attrs: { "data-testid": "loader-spinner" }
    },
    [_c("img", { attrs: { src: require("@/assets/svg/loader.svg") } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }