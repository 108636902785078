var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "admin-consent-container" }, [
    _c("div", { staticClass: "admin-consent-container__content" }, [
      _c("h3", [_vm._v("Admin consent")]),
      _c("p", [
        _vm._v(
          "In order to operate within your organization’s tenant, Bookado has to be approved. This approval is done by IT Administrator."
        )
      ]),
      _c("p", [
        _vm._v(
          "If you are an IT Administrator please follow the link and instructions below."
        )
      ]),
      _c(
        "div",
        { staticClass: "admin-consent-container__authorization-link" },
        [
          _vm._m(0),
          _c("div", { staticClass: "authorization-link__content" }, [
            _c(
              "div",
              { staticClass: "authorization-link__content__instruction" },
              [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "authorization-link__content__status" },
                  [
                    _vm.isLoadingConsentStatus
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/svg/loader.svg"),
                            alt: "loader"
                          }
                        })
                      : _c("consent-status", {
                          attrs: { status: _vm.consentStatus }
                        })
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "authorization-link__content__actions" }, [
              _c(
                "a",
                {
                  attrs: { href: _vm.consentLink || "#", target: "_blank" },
                  on: {
                    click: function() {
                      return _vm.saveConsentSettings(true)
                    }
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "primary-button small",
                      attrs: { disabled: !_vm.consentLink }
                    },
                    [_vm._v(" Consent ")]
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _c("h3", [_vm._v("Not an IT Administrator?")]),
      _c("p", [
        _vm._v(
          " Please let us know the e-mail address of your IT Administrator, so we could send the link to Admin Consent together with instructions. "
        )
      ]),
      _c("div", { staticClass: "admin-consent-container__email-to-admin" }, [
        _c("h3", [_vm._v("E-mail to IT Administrator")]),
        _c(
          "div",
          {
            staticClass:
              "admin-consent-container__email-to-admin__input-container"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              staticClass: "admin-consent-container__email-to-admin__input",
              attrs: {
                name: "email",
                type: "text",
                placeholder: "type e-mail address here..."
              },
              domProps: { value: _vm.email },
              on: {
                keyup: _vm.onEmailChanged,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            }),
            _c(
              "button",
              {
                staticClass: "primary-button small",
                attrs: { disabled: !_vm.isEmailValid || _vm.isSendingEmail },
                on: { click: _vm.sendAdminRequest }
              },
              [_vm._v(" Send ")]
            )
          ]
        ),
        _vm.showIsEmailSentMessage
          ? _c("div", { staticClass: "w-100" }, [
              _c("p", { staticClass: "success m-0 mt-3" }, [
                _vm._v(" " + _vm._s(_vm.emailSentMessage) + " ")
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("img", {
        staticClass: "authorization-link__heading__icon",
        attrs: {
          src: require("@/assets/svg/lock-in-shield.svg"),
          alt: "lock in shield"
        }
      }),
      _vm._v(" Authorization link for IT Administrator ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "authorization-link__content__list" }, [
      _c("li", { staticClass: "authorization-link__content__list-item" }, [
        _vm._v("Click the “Consent” button.")
      ]),
      _c("li", { staticClass: "authorization-link__content__list-item" }, [
        _vm._v("Read all necessary permissions that Bookado needs.")
      ]),
      _c("li", { staticClass: "authorization-link__content__list-item" }, [
        _vm._v("Confirm your consent.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "admin-consent-container__secondary" }, [
      _c("img", {
        staticClass: "settings-image",
        attrs: {
          src: require("@/assets/svg/settings-photo.svg"),
          alt: "settings"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }