var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "hide-steps": true } }),
      _c("div", { staticClass: "dice-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "title" }, [_vm._v("Access denied")]),
          _vm.isLoading
            ? _c("div", [
                _c("img", {
                  staticClass: "loader",
                  attrs: {
                    src: require("@/assets/svg/loader.svg"),
                    alt: "loader"
                  }
                }),
                _c("span", [_vm._v("Loading information...")])
              ])
            : _c("div", { staticClass: "content" }, [
                _c("p", [
                  _vm._v(
                    "The subscription for your organization is managed by Primary User. "
                  )
                ]),
                _vm.primaryUserEmail
                  ? _c("p", [
                      _vm._v("Please contact with: "),
                      _c(
                        "a",
                        { attrs: { href: "mailto:" + _vm.primaryUserEmail } },
                        [_vm._v(_vm._s(_vm.primaryUserEmail))]
                      ),
                      _vm._v(".")
                    ])
                  : _vm._e(),
                _vm._m(0)
              ])
        ])
      ]),
      _c("document-footer", { staticClass: "pt-4 pb-2" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "If you have problems or you are not able to contact with your colleague please write to "
      ),
      _c("a", { attrs: { href: "mailto:support@bookado.io" } }, [
        _vm._v("support@bookado.io")
      ]),
      _vm._v(".")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }