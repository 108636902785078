var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "modal overflow-modal",
          class: { "modal--visible": _vm.visible },
          attrs: { tabindex: "-1", role: "dialog" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered plans-modal",
              attrs: { role: "document" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-content px-5 py-4 dashboard-modal-content"
                },
                [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("h4", { staticClass: "modal-title" }, [
                      _vm._v("Choose your plan")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "close-button",
                        on: { click: _vm.onCancel }
                      },
                      [_c("i", { staticClass: "fa fa-times fa-fw" })]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "modal-body p-0 mt-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "plans-container d-flex justify-content-center"
                        },
                        _vm._l(_vm.plans, function(plan) {
                          return _c(
                            "div",
                            {
                              key: plan.id,
                              staticClass: "plan-container ml-3 mr-3",
                              class: {
                                selected: plan.id === _vm.selectedPlanId
                              }
                            },
                            [
                              _c("wizard-subscription-plan", {
                                attrs: { plan: plan },
                                on: {
                                  select: function($event) {
                                    return _vm.onPlanSelected(plan)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm.isPlansLoading || _vm.isSelectedPlanLoading
                        ? _c("overlay-loader", {
                            attrs: { "overlay-color": "#fff" }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "modal-footer d-flex justify-content-center"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "primary-button small",
                          attrs: {
                            "data-testid": "save-plan-button",
                            disabled: _vm.originalPlanId === _vm.selectedPlanId
                          },
                          on: { click: _vm.onSave }
                        },
                        [_vm._v("Save")]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      ),
      _c("change-subscription-plan-confirmation-modal", {
        attrs: {
          visible: _vm.isConfirmationModalVisible,
          "target-plan": _vm.selectedPlan,
          "source-plan": _vm.originalPlan
        },
        on: {
          cancel: _vm.onConfirmationModalCancel,
          confirm: _vm.onConfirmationModalConfirm
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }