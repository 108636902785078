var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("confirmation-modal", {
        attrs: {
          "is-visible": _vm.modalDataVModel.isVisible,
          heading: _vm.closeCounterSeconds
            ? _vm.isSuccessfullySubmitted
              ? _vm.modalLabels.afterConfirm.heading
              : ""
            : _vm.modalLabels.beforeConfirm.heading,
          "primary-text": _vm.closeCounterSeconds
            ? _vm.isSuccessfullySubmitted
              ? _vm.modalLabels.afterConfirm.primaryText
              : _vm.isSuccessfullySubmitted === false
              ? "An error occurred"
              : ""
            : _vm.modalLabels.beforeConfirm.primaryText,
          "secondary-text": _vm.closeCounterSeconds
            ? _vm.isSuccessfullySubmitted
              ? _vm.modalLabels.afterConfirm.secondaryText
              : _vm.isSuccessfullySubmitted === false
              ? "This action couldn't be performed, please try again later, sorry for inconvenience."
              : ""
            : _vm.modalLabels.beforeConfirm.secondaryText,
          "is-cancel-button-hidden": !!_vm.closeCounterSeconds,
          "custom-confirm-button-label": _vm.closeCounterSeconds
            ? _vm.getCloseModalButtonLabel(_vm.closeCounterSeconds)
            : null,
          "additional-element": _vm.modalDataVModel.isSubmitted
            ? _vm.isSuccessfullySubmitted === null
              ? _vm.loaderSpinnerComponent
              : null
            : _vm.additionalElement,
          "is-submit-disabled":
            _vm.modalDataVModel.isSubmitDisabled ||
            (_vm.modalDataVModel.isSubmitted &&
              _vm.isSuccessfullySubmitted === null)
        },
        on: {
          cancel: function($event) {
            _vm.modalDataVModel.isVisible = false
          },
          confirm: function($event) {
            _vm.closeCounterSeconds
              ? (function() {
                  _vm.clearInterval(_vm.modalCloseCounterInterval)
                  _vm.closeCounterSeconds = 0
                  _vm.modalDataVModel.isVisible = false
                })()
              : _vm.onConfirm()
          }
        },
        model: {
          value: _vm.modalDataVModel.additionalElementData,
          callback: function($$v) {
            _vm.$set(_vm.modalDataVModel, "additionalElementData", $$v)
          },
          expression: "modalDataVModel.additionalElementData"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }