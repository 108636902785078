var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-account-widget-container" },
    [
      _vm.user
        ? [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center user-account-widget",
                attrs: { "data-testid": "user-menu-button" },
                on: { click: _vm.toggleAccountOptions }
              },
              [
                _vm.user
                  ? _c("user-card", { attrs: { user: _vm.user } })
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "menu-toggler ml-3",
                    attrs: { type: "button" }
                  },
                  [
                    !_vm.isAccountOptionsExpanded
                      ? _c("i", { staticClass: "fa fa-chevron-down fa-fw" })
                      : _vm._e(),
                    _vm.isAccountOptionsExpanded
                      ? _c("i", { staticClass: "fa fa-chevron-up fa-fw" })
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.onClickOutside,
                    expression: "onClickOutside"
                  }
                ],
                staticClass: "account-options p-0",
                class: { visible: _vm.isAccountOptionsExpanded }
              },
              [
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "w-100",
                      attrs: { "data-testid": "logout-button" },
                      on: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [_vm._v("Logout")]
                  )
                ])
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }