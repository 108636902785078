var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal--light",
      class: { "modal--visible": _vm.isVisible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal-dialog-centered dashboard-modal markers-modal",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-content px-5 py-4 dashboard-modal-content" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between markers-modal-header"
                },
                [
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.heading))
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body markers-modal-body p-0 mt-4" },
                [
                  _c(
                    "div",
                    { staticClass: "description align-items-center" },
                    [
                      _vm.primaryText
                        ? _c("p", {
                            domProps: { innerHTML: _vm._s(_vm.primaryText) }
                          })
                        : _vm._e(),
                      _vm.secondaryText
                        ? [
                            _c("p", {
                              domProps: { innerHTML: _vm._s(_vm.secondaryText) }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm.additionalElement
                    ? [
                        _c("additionalElement", {
                          model: {
                            value: _vm.additionalElementVModel,
                            callback: function($$v) {
                              _vm.additionalElementVModel = $$v
                            },
                            expression: "additionalElementVModel"
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex justify-content-end px-0 mt-3"
                },
                [
                  !_vm.isCancelButtonHidden
                    ? _c(
                        "button",
                        {
                          staticClass: "secondary-button",
                          on: { click: _vm.onCancel }
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e(),
                  _vm.isDiscardButtonShowed
                    ? _c(
                        "button",
                        {
                          staticClass: "secondary-button",
                          on: { click: _vm.onDiscard }
                        },
                        [_vm._v("Discard")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "primary-button small",
                      attrs: { disabled: _vm.isSubmitDisabled },
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v(_vm._s(_vm.customConfirmButtonLabel || "Confirm"))]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }