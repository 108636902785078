var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.plan
    ? _c(
        "div",
        {
          staticClass: "subscription-plan d-flex align-items-end flex-column",
          class: { recommended: _vm.plan.isRecommended },
          on: { click: _vm.onSelectPlan }
        },
        [
          _c("div", { staticClass: "header d-flex justify-content-between" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.plan.name))
            ]),
            !_vm.plan.isAvailable
              ? _c(
                  "span",
                  { staticClass: "badge badge-success align-self-center" },
                  [_vm._v("SOON")]
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "pricing" }, [
            _c(
              "div",
              { staticClass: "price-container d-flex justify-content-between" },
              [
                _c("p", { staticClass: "price" }, [
                  _vm._v(
                    _vm._s(_vm.plan.pricePerRoom) +
                      _vm._s(_vm.plan.currency) +
                      " /mon"
                  )
                ]),
                _c(
                  "span",
                  { staticClass: "badge badge-secondary align-self-baseline" },
                  [_vm._v("ROOM")]
                )
              ]
            ),
            _vm.plan.pricePerDesk
              ? _c(
                  "div",
                  {
                    staticClass:
                      "price-container d-flex justify-content-between"
                  },
                  [
                    _c("p", { staticClass: "price" }, [
                      _vm._v(
                        _vm._s(_vm.plan.pricePerDesk) +
                          _vm._s(_vm.plan.currency) +
                          " /mon"
                      )
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "badge badge-secondary align-self-baseline"
                      },
                      [_vm._v("DESK")]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _c(
            "ul",
            { staticClass: "features" },
            _vm._l(_vm.plan.features, function(feature) {
              return _c("li", { key: feature }, [
                _c("i", { staticClass: "fa fa-check fa-fw" }),
                _vm._v(_vm._s(feature))
              ])
            }),
            0
          ),
          _vm.plan.isAvailable
            ? _c(
                "div",
                {
                  staticClass: "footer mt-auto",
                  attrs: { "data-testid": _vm.plan.name }
                },
                [_vm._v("Choose " + _vm._s(_vm.plan.name))]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }