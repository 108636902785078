var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "selected-step": 2 } }),
      _c("div", { staticClass: "dice-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("Your organization details")
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("organization-details-form", {
                attrs: { "on-submit$": _vm.onSubmit$, "is-onboarding": true },
                on: {
                  submitted: _vm.onFormSubmitted,
                  isLoading: _vm.onLoadingChanged
                },
                model: {
                  value: _vm.organizationFormValidity,
                  callback: function($$v) {
                    _vm.organizationFormValidity = $$v
                  },
                  expression: "organizationFormValidity"
                }
              }),
              _vm.isLoading ? _c("overlay-loader") : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "placeholder" }),
            _c(
              "button",
              {
                staticClass: "primary-button",
                attrs: {
                  id: "onboarding-step-2-continue",
                  disabled:
                    _vm.isLoading || _vm.organizationFormValidity.isEmpty
                },
                on: { click: _vm.onContinue }
              },
              [_vm._v("Continue")]
            )
          ])
        ])
      ]),
      _c("document-footer", { staticClass: "pt-4 pb-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }