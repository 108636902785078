var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass: "glitch",
            attrs: { "data-text": "Page not found", "data-cy": "goToMainPage" }
          },
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v("Page not found")
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }