var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "edit-container d-flex justify-content-between py-3 px-5"
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              !_vm.isNoPreviewMode
                ? _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "header borderless backgroundless py-3 pl-0"
                      },
                      [_vm._v(_vm._s(_vm.resourceTypeLabel) + " PHOTO")]
                    ),
                    _vm.data.hasCustomImage
                      ? _c(
                          "button",
                          {
                            staticClass: "secondary-button change-photo-button",
                            attrs: { "data-testid": "change-photo-button" },
                            on: {
                              click: function($event) {
                                return _vm.onChangeImageClicked()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-upload fa-fw" }),
                            _vm._v(" Change photo ")
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "cropper-container",
                  style: { display: _vm.isLoadingPreview ? "hidden" : "block" }
                },
                [
                  _c("resource-photo-cropper", {
                    attrs: {
                      "original-image": _vm.currentOriginalImage,
                      coords: _vm.customImageCoords || _vm.currentImageCoords,
                      "current-preview-type": _vm.currentPreviewType,
                      "show-loader":
                        _vm.isLoadingPreview && !_vm.isNoPreviewMode,
                      readonly: _vm.shouldForceEditMode
                        ? false
                        : _vm.isDisplayingDefaultImage,
                      "accepts-empty-coordinates": _vm.isNoPreviewMode,
                      "force-every-emit-of-cropper-change":
                        _vm.forceEveryEmitOfCropperChange,
                      "resource-type": _vm.resourceType
                    },
                    on: {
                      imageCrop: _vm.onImageCropped,
                      cropperIsReady: _vm.onCropperIsReady
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center mt-4"
                },
                [
                  _c("resource-photo-picker", {
                    attrs: {
                      model: _vm.data,
                      "change-photo$": _vm.changePhoto$,
                      "is-no-thumbnail-mode": _vm.isNoChangePhotoThumbnailMode,
                      "is-default-image-active": _vm.isDefaultImageActive
                    },
                    on: {
                      imageSelected: function($event) {
                        return _vm.onImageSelected($event)
                      }
                    }
                  }),
                  (!_vm.data.isPristine ||
                    _vm.initialIsDefaultImageActive !==
                      _vm.isDefaultImageActive) &&
                  !_vm.isNoPreviewMode
                    ? _c("div", [
                        _c("div", { staticClass: "buttons-container" }, [
                          _c(
                            "button",
                            {
                              staticClass: "secondary-button mr-2",
                              attrs: {
                                "data-testid": "preview-photo-save-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onCancelImageClicked()
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "primary-button small",
                              attrs: {
                                "data-testid": "preview-photo-cancel-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSaveImageClicked()
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ]),
            !_vm.isNoPreviewMode
              ? _c("resource-photo-previews-list", {
                  attrs: {
                    "previews-configs": _vm.previewsConfigs,
                    "current-preview-type": _vm.currentPreviewType,
                    "is-loading-preview": _vm.isLoadingPreview
                  },
                  on: {
                    previewTypeChange: function($event) {
                      return _vm.onPreviewClick($event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }