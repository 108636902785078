var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "selected-step": 1 } }),
      _c("div", { staticClass: "dice-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              " We are happy that you want to join us and make your office space smarter "
            )
          ]),
          _c("div", { staticClass: "content" }, [
            _c("p", [
              _vm._v(
                " On next pages you will be able to create subscription to Bookado for your organization and enable your employee to save time and focus on most important activities. "
              )
            ]),
            _c("p", { staticClass: "bolded-paragraph" }, [
              _vm._v("Let's make that change together!")
            ]),
            _c("ul", { staticClass: "consents-list" }, [
              _c(
                "li",
                [
                  _c("checkbox", {
                    attrs: {
                      "data-testid": "admin-consent-checkbox",
                      model: _vm.adminConsent,
                      label: "I confirm I can act on behalf of my organization",
                      "is-required": true,
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.adminConsent,
                      callback: function($$v) {
                        _vm.adminConsent = $$v
                      },
                      expression: "adminConsent"
                    }
                  })
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("checkbox", {
                    attrs: {
                      "data-testid": "terms-consent-checkbox",
                      model: _vm.termsConsent,
                      label: "I confirm I have read and accepted",
                      "is-required": true,
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.termsConsent,
                      callback: function($$v) {
                        _vm.termsConsent = $$v
                      },
                      expression: "termsConsent"
                    }
                  }),
                  _c("div", { staticClass: "consent-documents" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.termsAndConditionsLink,
                          target: "_blank"
                        }
                      },
                      [_vm._v("Terms of use")]
                    ),
                    _vm._v(" and "),
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.privacyPolicyLink, target: "_blank" }
                      },
                      [_vm._v("Privacy Policy")]
                    )
                  ])
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("p", { staticClass: "bolded-paragraph" }, [
              _vm._v("Sign in using your company credentials")
            ]),
            _c(
              "div",
              { staticClass: "login-vendors" },
              [
                _c(
                  "login-button",
                  {
                    attrs: {
                      id: "onboarding-step-1-google",
                      "data-testid": "google-login-button",
                      label: "Sign in with Google",
                      enabled: _vm.isConsentGranted
                    },
                    on: { click: _vm.loginGoogle }
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        slot: "icon",
                        src: require("@/assets/img/google-logo.png")
                      },
                      slot: "icon"
                    })
                  ]
                ),
                _c(
                  "login-button",
                  {
                    attrs: {
                      id: "onboarding-step-1-microsoft",
                      "data-testid": "microsoft-login-button",
                      label: "Sign in with Microsoft",
                      enabled: _vm.isConsentGranted
                    },
                    on: { click: _vm.loginMicrosoft }
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        slot: "icon",
                        src: require("@/assets/img/ms-logo.png")
                      },
                      slot: "icon"
                    })
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("document-footer", { staticClass: "pt-4 pb-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }