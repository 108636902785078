var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered change-plan-modal",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-content px-5 py-4 dashboard-modal-content",
              attrs: { "data-testid": "change-subscription-plan-modal" }
            },
            [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c("h4", { staticClass: "modal-title" }, [
                  _vm._v("Important!")
                ]),
                _c(
                  "div",
                  { staticClass: "close-button", on: { click: _vm.onCancel } },
                  [_c("i", { staticClass: "fa fa-times fa-fw" })]
                )
              ]),
              _vm.targetPlan
                ? _c(
                    "div",
                    { staticClass: "modal-body p-0 mt-4" },
                    [
                      _vm.isUpgraded
                        ? [
                            _c("p", [
                              _vm._v(
                                "This action has impact on functionality and cost."
                              )
                            ]),
                            _c("p", [
                              _vm._v(
                                "If you continue users will be able to use the following new functionalities:"
                              )
                            ]),
                            _c(
                              "ul",
                              { staticClass: "features" },
                              _vm._l(_vm.features, function(feature) {
                                return _c("li", { key: feature }, [
                                  _c("i", {
                                    staticClass: "fa fa-check fa-fw upgraded"
                                  }),
                                  _vm._v(" " + _vm._s(feature))
                                ])
                              }),
                              0
                            ),
                            _c("p", [
                              _vm._v(
                                "If you continue you agree that your monthly costs will increase by 34 EUR. New monthy cost would be 185 EUR."
                              )
                            ])
                          ]
                        : [
                            _c("p", [
                              _vm._v(
                                "This action has impact on functionality and cost."
                              )
                            ]),
                            _c("p", [
                              _vm._v(
                                "If you continue users will loose the following functionalities:"
                              )
                            ]),
                            _c(
                              "ul",
                              { staticClass: "features" },
                              _vm._l(_vm.features, function(feature) {
                                return _c("li", { key: feature }, [
                                  _c("i", { staticClass: "fa fa-times fa-fw" }),
                                  _vm._v(_vm._s(feature))
                                ])
                              }),
                              0
                            )
                          ]
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "modal-footer d-flex justify-content-center" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "secondary-button",
                      attrs: { "data-testid": "cancel-button" },
                      on: { click: _vm.onCancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "primary-button small",
                      attrs: { "data-testid": "confirm-button" },
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v("Confirm")]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }