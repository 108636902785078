var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "organization-details-form" }, [
    _c(
      "div",
      { staticClass: "toasts-container" },
      [_c("toast", { attrs: { messages: _vm.toastMessage$ } })],
      1
    ),
    _c(
      "div",
      { staticClass: "section" },
      [
        _c("div", { staticClass: "w-100" }, [
          _c("label", [_vm._v("Organization legal name")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.subscriptionForm.organizationName,
                expression: "subscriptionForm.organizationName"
              }
            ],
            attrs: { name: "organization-name", type: "text" },
            domProps: { value: _vm.subscriptionForm.organizationName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.subscriptionForm,
                  "organizationName",
                  $event.target.value
                )
              }
            }
          }),
          _vm.errors.organizationName
            ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                _vm._v("Field is required.")
              ])
            : _vm._e(),
          _vm.isOnboarding
            ? _c("div", { staticClass: "tenant-id-container" }, [
                _c("label", [_vm._v("Microsoft Tenant ID")]),
                _c("span", { staticClass: "guid" }, [
                  _vm._v(_vm._s(_vm.subscriptionForm.tenantId))
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "w-100 pt-3" }, [
          _c("label", [_vm._v("Industry")]),
          _vm.industryOptions
            ? _c(
                "select",
                {
                  staticClass: "organization-vat-id-is-defined",
                  on: {
                    change: function($event) {
                      return _vm.onIndustryOptionChange($event)
                    }
                  }
                },
                [
                  !_vm.subscriptionForm.industry
                    ? _c(
                        "option",
                        {
                          key: null,
                          domProps: {
                            selected: !_vm.subscriptionForm.industry,
                            value: null
                          }
                        },
                        [_vm._v("No industry selected ")]
                      )
                    : _vm._e(),
                  _vm._l(_vm.industryOptions, function(industryOption) {
                    return _c(
                      "option",
                      {
                        key: industryOption,
                        domProps: {
                          value: industryOption,
                          selected: _vm.isIndustrySelected(industryOption)
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getIndustryLabel(industryOption)) +
                            " "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            : _c(
                "div",
                {
                  staticClass: "industries__loader",
                  class: { white: _vm.isOnboarding }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/svg/loader.svg"),
                      alt: "loader"
                    }
                  }),
                  _c("span", [_vm._v("Loading industries options...")])
                ]
              ),
          _vm.errors.industry && !_vm.subscriptionForm.industry
            ? _c("p", { staticClass: "error" }, [
                _vm._v("Industry is required.")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "w-100 pt-3 pb-3" }, [
          _c("label", [_vm._v("Street")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.subscriptionForm.street,
                expression: "subscriptionForm.street"
              }
            ],
            attrs: { name: "street", type: "text" },
            domProps: { value: _vm.subscriptionForm.street },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.subscriptionForm, "street", $event.target.value)
              }
            }
          }),
          _vm.errors.street
            ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                _vm._v("Field is required.")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "w-100 pt-3 pb-3 d-flex" }, [
          _c("div", { staticClass: "mr-3" }, [
            _c("label", [_vm._v("Postal code")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.subscriptionForm.postcode,
                  expression: "subscriptionForm.postcode"
                }
              ],
              attrs: { name: "postcode", type: "text" },
              domProps: { value: _vm.subscriptionForm.postcode },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.subscriptionForm,
                    "postcode",
                    $event.target.value
                  )
                }
              }
            }),
            _vm.errors.postcode
              ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                  _vm._v("Field is required.")
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "w-100" }, [
            _c("label", [_vm._v("City")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.subscriptionForm.city,
                  expression: "subscriptionForm.city"
                }
              ],
              attrs: { name: "city", type: "text" },
              domProps: { value: _vm.subscriptionForm.city },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.subscriptionForm, "city", $event.target.value)
                }
              }
            }),
            _vm.errors.city
              ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                  _vm._v("Field is required.")
                ])
              : _vm._e()
          ])
        ]),
        _c(
          "div",
          { staticClass: "w-100 pt-3 pb-3" },
          [
            _c("label", [_vm._v("Country")]),
            _c("country-picker", {
              model: {
                value: _vm.subscriptionForm.countryIsoCode,
                callback: function($$v) {
                  _vm.$set(_vm.subscriptionForm, "countryIsoCode", $$v)
                },
                expression: "subscriptionForm.countryIsoCode"
              }
            }),
            _vm.errors.countryIsoCode
              ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                  _vm._v("Field is required.")
                ])
              : _vm._e()
          ],
          1
        ),
        _c("organization-details-form-vat-id", {
          model: {
            value: _vm.subscriptionForm,
            callback: function($$v) {
              _vm.subscriptionForm = $$v
            },
            expression: "subscriptionForm"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }