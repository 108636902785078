var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _vm._v(" " + _vm._s(_vm.visible) + " "),
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered photo-upload-modal",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-content px-5 py-4" },
            [
              !_vm.isFileUploaded
                ? [
                    _c("div", { staticClass: "d-flex justify-content-end" }, [
                      _c(
                        "div",
                        {
                          staticClass: "close-button",
                          on: { click: _vm.onCancel }
                        },
                        [_c("i", { staticClass: "fa fa-times fa-fw" })]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "modal-body p-0 mt-4 d-flex flex-column" },
                      [
                        _c(
                          "div",
                          {
                            ref: "dropArea",
                            staticClass: "drop-area",
                            class: { dragging: _vm.isDraggingFile },
                            on: { drop: _vm.onFilesDropped }
                          },
                          [
                            _vm._m(0),
                            !_vm.dropError
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "instruction instruction--primary"
                                  },
                                  [_vm._v("Drag and drop file here")]
                                )
                              : _vm._e(),
                            !!_vm.dropError
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "instruction instruction--primary error"
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-exclamation-triangle fa-fw"
                                    }),
                                    _vm._v(" " + _vm._s(_vm.dropError))
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "p",
                          { staticClass: "instruction instruction--secondary" },
                          [_vm._v("Or")]
                        ),
                        _c("input", {
                          ref: "fileInput",
                          staticClass: "browse-file-input",
                          attrs: {
                            id: "fileInput",
                            type: "file",
                            "data-testid": "upload-photo-input",
                            accept: _vm.acceptTypes
                          },
                          on: {
                            change: function($event) {
                              return _vm.onFileUploaded($event)
                            }
                          }
                        }),
                        _c("div", { staticClass: "w-100 mb-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "primary-button",
                              on: {
                                click: function($event) {
                                  return _vm.onFileBrowseClicked()
                                }
                              }
                            },
                            [_vm._v(" Browse your file ")]
                          )
                        ])
                      ]
                    )
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "modal-body p-0 mt-5 d-flex flex-column",
                        attrs: { "data-testid": "upload-file-container" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "upload-file-card mb-3 d-flex justify-content-start w-100"
                          },
                          [
                            _vm._m(1),
                            _c(
                              "div",
                              {
                                staticClass: "card-details d-flex flex-column"
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "details details--primary" },
                                  [_vm._v(_vm._s(_vm.fileName))]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "details details--secondary mb-0"
                                  },
                                  [_vm._v(_vm._s(_vm.fileSize) + " KB")]
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "progress-bar" }),
                        _c("div", { staticClass: "w-100 mb-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "primary-button",
                              attrs: {
                                "data-testid": "upload-photo-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.emitAndClose()
                                }
                              }
                            },
                            [_vm._v(" Close ")]
                          )
                        ])
                      ]
                    )
                  ]
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("img", { attrs: { src: require("@/assets/svg/upload.svg") } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-icon mr-3" }, [
      _c("img", { attrs: { src: require("@/assets/svg/upload-file.svg") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }