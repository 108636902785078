var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal-dialog-centered dashboard-modal markers-modal",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-content px-5 py-4 dashboard-modal-content" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between markers-modal-header"
                },
                [
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v("Do you want to change the default description?")
                  ]),
                  _c(
                    "div",
                    { staticClass: "close-button", on: { click: _vm.onClose } },
                    [_c("i", { staticClass: "fa fa-times fa-fw" })]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body markers-modal-body p-0 mt-4" },
                [[_vm._m(0)]],
                2
              ),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex justify-content-start px-0 mt-3"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "secondary-button",
                      on: { click: _vm.onCancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "primary-button small",
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v("Confirm")]
                  )
                ]
              ),
              _vm._m(1)
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description align-items-center" }, [
      _c("p", [
        _c("img", { attrs: { src: require("@/assets/svg/icon-warning.svg") } }),
        _vm._v("This action cannot be undone.")
      ]),
      _c("p", [
        _vm._v(
          "Provided description will be used as a default for all resources of given type."
        )
      ]),
      _c("p"),
      _c("p", [_vm._v("Please confirm to proceed.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "theme-image-container" }, [
      _c("img", {
        staticClass: "theme-image",
        attrs: { src: require("@/assets/svg/set-as-default-photo.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }