var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c("div", { staticClass: "wizard-team-member" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between" },
          [
            _c("user-card", { attrs: { user: _vm.model } }),
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _vm.canDelete
                ? _c(
                    "div",
                    {
                      staticClass: "details-trash align-self-center mr-3",
                      on: { click: _vm.onDelete }
                    },
                    [_c("i", { staticClass: "fa fa-trash fa-fw" })]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "details-toggle align-self-center",
                  on: { click: _vm.onToggleClick }
                },
                [
                  _vm.isExpanded
                    ? _c("i", { staticClass: "fa fa-fw fa-chevron-up" })
                    : _vm._e(),
                  !_vm.isExpanded
                    ? _c("i", { staticClass: "fa fa-fw fa-chevron-down" })
                    : _vm._e()
                ]
              )
            ])
          ],
          1
        ),
        _vm.isExpanded
          ? _c("div", { staticClass: "editor" }, [
              _c("div", { staticClass: "editor-property" }, [
                _c("label", [_vm._v("Phone number")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.phoneNumber,
                      expression: "model.phoneNumber"
                    }
                  ],
                  attrs: { name: "phone-number", type: "text" },
                  domProps: { value: _vm.model.phoneNumber },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "phoneNumber", $event.target.value)
                      },
                      _vm.onPhoneChanged
                    ]
                  }
                }),
                _vm.isPhoneError && !_vm.isPristine
                  ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                      _vm._v(_vm._s(_vm.errorMessage))
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "editor-property" },
                [
                  _c("checkbox", {
                    attrs: {
                      model: _vm.model.isInvoicesRecipient,
                      label: "Send invoices to this contact"
                    },
                    on: { input: _vm.onModelChanged },
                    model: {
                      value: _vm.model.isInvoicesRecipient,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "isInvoicesRecipient", $$v)
                      },
                      expression: "model.isInvoicesRecipient"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }