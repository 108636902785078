var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "hide-steps": true } }),
      _c("div", { staticClass: "dice-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "title" }, [_vm._v("Welcome to Bookado!")]),
          _c("div", { staticClass: "content" }, [
            _c("h5", { staticClass: "pb-3" }, [_vm._v("Bookado Rooms")]),
            _c(
              "div",
              {
                staticClass:
                  "section rooms-section d-flex justify-content-between pb-3"
              },
              [
                _c(
                  "div",
                  { staticClass: "rooms-counter d-flex justify-content-start" },
                  [
                    _c("span", [
                      _vm._v("Available rooms: " + _vm._s(_vm.totalRooms))
                    ])
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "primary-button small refresh-button",
                    on: { click: _vm.refreshResources }
                  },
                  [
                    _c("i", { staticClass: "fa fa-refresh fa-fw" }),
                    _vm._v(" Refresh data ")
                  ]
                )
              ]
            )
          ])
        ])
      ]),
      _vm.isLoading ? _c("overlay-loader") : _vm._e(),
      _c("document-footer", { staticClass: "pt-4 pb-2" }),
      _c("subscription-resources-recalculation-modal", {
        attrs: { visible: _vm.isRecalculationModalVisible }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }