var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card__container" }, [
    _c("h1", { staticClass: "card__card-number" }, [
      _vm._v("**** **** **** " + _vm._s(_vm.last4Digits))
    ]),
    _c("span", { staticClass: "card__expiration-date" }, [
      _vm._v(_vm._s(_vm.expirationDate))
    ]),
    _c("span", { staticClass: "card__billing-name" }, [
      _vm._v(_vm._s(_vm.billingName))
    ]),
    _vm.paymentProcessingNetworkLogoPath
      ? _c("div", { staticClass: "card-provider__container" }, [
          _c("img", { attrs: { src: _vm.paymentProcessingNetworkLogoPath } })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }