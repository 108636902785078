var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-content px-5 py-4 dashboard-modal-content" },
            [
              _c("h4", { staticClass: "modal-title mt-2 mb-4" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex justify-content-between mx-0 px-0"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "secondary-button",
                      attrs: {
                        "data-testid": "cancel-delete-team-member-button"
                      },
                      on: { click: _vm.onCancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "primary-button small",
                      attrs: {
                        "data-testid": "confirm-delete-team-member-button"
                      },
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v(_vm._s(_vm.confirmButtonLabel))]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }