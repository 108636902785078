var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", {
        attrs: { "hide-steps": true, "hide-account": true }
      }),
      _c("div", { staticClass: "dice-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "title" }, [_vm._v("Thank you!")]),
          _vm._m(0),
          _c("div", { staticClass: "footer" }, [
            _c("p", { staticClass: "bolded-paragraph" }, [
              _vm._v("You will be redirected to "),
              _c("a", { attrs: { href: _vm.bookadoUrl } }, [
                _vm._v("Bookado.io")
              ]),
              _vm._v(" in " + _vm._s(_vm.remainingSeconds) + " seconds...")
            ])
          ]),
          _vm._m(1)
        ])
      ]),
      _c("div", { staticClass: "pt-4 pb-2 d-flex justify-content-center" }, [
        _c("small", [
          _vm._v(" Support: "),
          _c(
            "a",
            {
              staticClass: "ml-1",
              attrs: { href: "mailto:" + _vm.supportEmail }
            },
            [_vm._v(_vm._s(_vm.supportEmail))]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("p", [_vm._v("Bookado permissions has been successfully granted.")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "theme-image-container" }, [
      _c("img", {
        staticClass: "theme-image",
        attrs: { src: require("@/assets/svg/markers-generated.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }