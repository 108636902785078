var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _c(
      "table",
      { staticClass: "table table-borderless subscription-resources-grid" },
      [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.resources, function(resource) {
            return _c("tr", { key: resource.name }, [
              _c("td", [_vm._v(_vm._s(resource.name))]),
              _c("td", [_vm._v(_vm._s(resource.quantity))]),
              _c("td", [
                _vm._v(
                  _vm._s(resource.pricePerUnit) + " " + _vm._s(_vm.currency)
                )
              ]),
              _c("td", [
                _vm._v(_vm._s(resource.totalPrice) + " " + _vm._s(_vm.currency))
              ])
            ])
          }),
          0
        ),
        _c("tfoot", [
          _c("td", { staticClass: "px-0", attrs: { colspan: "4" } }, [
            _c("div", { staticClass: "d-flex w-100 justify-content-between" }, [
              _c("span", [_vm._v("Total Price")]),
              _c("span", { staticClass: "mr-3" }, [
                _vm._v(
                  _vm._s(_vm.grandTotalPrice) +
                    " " +
                    _vm._s(_vm.currency) +
                    " / month"
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", width: "44%" } }, [
          _c("i", { staticClass: "fa fa-calendar fa-fw mr-2" }),
          _vm._v(" ITEMS ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-list fa-fw mr-2" }),
          _vm._v(" QUANTITY ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-money fa-fw mr-2" }),
          _vm._v(" COST ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-database fa-fw mr-2" }),
          _vm._v(" TOTAL ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }