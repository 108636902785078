var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.breadcrumbPaths, function(breadcrumb) {
      return _c("li", { key: breadcrumb.name }, [
        breadcrumb.path && breadcrumb.path.length > 0
          ? _c("a", { attrs: { href: breadcrumb.path } }, [
              _vm._v(_vm._s(breadcrumb.name))
            ])
          : _c("span", [_vm._v(_vm._s(breadcrumb.name))])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }