var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 pt-3 pb-3 position-relative" },
    [
      _c("label", [_vm._v("Organization VAT ID")]),
      _c(
        "select",
        {
          staticClass: "organization-vat-id-is-defined",
          on: {
            change: function($event) {
              return _vm.onVatIdIsDefinedOptionChange($event)
            }
          }
        },
        _vm._l(_vm.isVatIdDefinedOptions, function(isVatIdDefinedOption) {
          return _c(
            "option",
            {
              key: isVatIdDefinedOption.value,
              domProps: {
                value: isVatIdDefinedOption.value,
                selected:
                  isVatIdDefinedOption.value ===
                  _vm.subscriptionForm.isVatIdDefined
              }
            },
            [_vm._v(_vm._s(isVatIdDefinedOption.label))]
          )
        }),
        0
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.subscriptionForm.vatId,
            expression: "subscriptionForm.vatId"
          }
        ],
        attrs: {
          name: "vatId",
          type: "text",
          disabled:
            !_vm.subscriptionForm.isVatIdDefined ||
            !_vm.subscriptionForm.countryIsoCode,
          placeholder: _vm.getCurrentCountryExampleVatId(),
          maxlength: _vm.maxVatIdLength
        },
        domProps: { value: _vm.subscriptionForm.vatId },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.subscriptionForm, "vatId", $event.target.value)
          }
        }
      }),
      _vm.isVatIdEmpty()
        ? _c("p", { staticClass: "error" }, [
            _vm._v(
              " Please provide VAT ID, because VAT payer option is selected "
            )
          ])
        : _vm._e(),
      !_vm.subscriptionForm.countryIsoCode
        ? _c("p", { staticClass: "error" }, [
            _vm._v(
              " You have to select a country before you can provide VAT ID "
            )
          ])
        : _vm._e(),
      _c(
        "input-tooltip",
        {
          staticClass: "vat-tooltip position-absolute",
          attrs: { text: "Your organization's VAT ID", placement: "bottom" }
        },
        [
          _c("i", {
            staticClass: "fa fa-question fa-fw",
            attrs: { slot: "icon" },
            slot: "icon"
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }