var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "hide-steps": true } }),
      _c("div", { staticClass: "dice-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "title" }, [_vm._v("Logged out")]),
          _c("div", { staticClass: "content" }, [
            _c("p", [
              _vm._v("You will be redirected to "),
              _c("a", { attrs: { href: _vm.bookadoUrl } }, [
                _vm._v("bookado.io")
              ]),
              _vm._v(" in " + _vm._s(_vm.countdown) + " seconds...")
            ])
          ])
        ])
      ]),
      _c("document-footer", { staticClass: "pt-4 pb-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }