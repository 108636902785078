var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.config
    ? _c(
        "div",
        {
          class: "preview-container " + (_vm.isActive ? "active" : ""),
          on: { click: _vm.onClick }
        },
        [
          _c("img", {
            staticClass: "template",
            attrs: { src: _vm.config.previewImage, alt: "preview image" }
          }),
          _c("img", {
            staticClass: "crop",
            style: {
              left: _vm.config.previewConfig.x + "px",
              top: _vm.config.previewConfig.y + "px"
            },
            attrs: {
              src: _vm.config.cropImage,
              width: _vm.config.previewConfig.w
            }
          }),
          _c("div", {
            staticClass: "text-area",
            style: {
              top: _vm.config.textAreaConfig.y + "px",
              left: _vm.config.textAreaConfig.x + "px",
              width: _vm.config.textAreaConfig.w + "px",
              height: _vm.config.textAreaConfig.h + "px"
            },
            domProps: { innerHTML: _vm._s(_vm.formattedDescription) }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }