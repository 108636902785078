var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.path && _vm.path.length > 0
    ? _c("div", { staticClass: "back-button-container mb-2" }, [
        _c("a", { attrs: { href: _vm.path } }, [
          _c("img", { attrs: { src: require("@/assets/svg/back-arrow.svg") } })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }