var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "label",
        {
          staticClass: "deactivation-reasons__label",
          attrs: { for: "deactivation-reasons" }
        },
        [_vm._v("Reason to close subscription:")]
      ),
      !_vm.resignationReasonsOptions ||
      (_vm.resignationReasonsOptions &&
        _vm.resignationReasonsOptions.length === 0)
        ? _c("loader-spinner", {
            model: {
              value: _vm.loaderSpinnerData,
              callback: function($$v) {
                _vm.loaderSpinnerData = $$v
              },
              expression: "loaderSpinnerData"
            }
          })
        : _c(
            "select",
            {
              attrs: { id: "deactivation-reasons" },
              on: {
                change: function($event) {
                  return _vm.onChange($event)
                }
              }
            },
            [
              _c(
                "option",
                {
                  key: null,
                  domProps: {
                    selected: !_vm.propsData.subscriptionDeactivationReason,
                    value: null
                  }
                },
                [_vm._v("Please select a reason")]
              ),
              _vm._l(_vm.resignationReasonsOptions, function(
                resignationReasonOption
              ) {
                return _c(
                  "option",
                  {
                    key: resignationReasonOption,
                    domProps: {
                      value: resignationReasonOption,
                      selected: _vm.isResignationReasonSelected(
                        resignationReasonOption
                      )
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.getResignationReasonLabel(resignationReasonOption)
                      )
                    )
                  ]
                )
              })
            ],
            2
          ),
      _vm.propsData.subscriptionDeactivationReason &&
      _vm.propsData.subscriptionDeactivationReason.explanationPossible
        ? _c(
            "div",
            { staticClass: "deactivation-reason-textarea-container" },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.propsData.deactivationReasonAdditionalInfo,
                    expression: "propsData.deactivationReasonAdditionalInfo"
                  }
                ],
                staticClass: "deactivation-reason-textarea",
                attrs: {
                  name: "deactivation-reason-textarea",
                  type: "text",
                  maxlength: _vm.deactivationReasonTextareaMaxLength,
                  placeholder: "Additional information..."
                },
                domProps: {
                  value: _vm.propsData.deactivationReasonAdditionalInfo
                },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.propsData,
                      "deactivationReasonAdditionalInfo",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("characters-limit-counter", {
                attrs: {
                  "current-model":
                    _vm.propsData.deactivationReasonAdditionalInfo,
                  "max-number-of-characters":
                    _vm.deactivationReasonTextareaMaxLength
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }