var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "hide-steps": true } }),
      _vm._m(0),
      _c("document-footer", { staticClass: "pt-4 pb-2" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dice-container" }, [
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Access denied")]),
        _c("div", { staticClass: "content" }, [
          _c("p", [
            _vm._v("You are not permitted to access Bookado Admin Portal.")
          ]),
          _c("p", [
            _vm._v(
              "Please use different account or contact your administrator."
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }