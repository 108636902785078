var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      staticClass: "payment-method-selector mb-3",
      class: { wide: _vm.isWide },
      on: {
        change: function($event) {
          return _vm.onChange($event)
        }
      }
    },
    _vm._l(_vm.paymentMethods, function(paymentMethod) {
      return _c(
        "option",
        {
          key: paymentMethod.value,
          domProps: {
            value: paymentMethod.value,
            selected: paymentMethod.value === _vm.payMethod
          }
        },
        [_vm._v(_vm._s(paymentMethod.label))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }