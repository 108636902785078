var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.countries
    ? _c(
        "select",
        {
          on: {
            change: function($event) {
              return _vm.onChange($event)
            }
          }
        },
        [
          _c("option", { attrs: { value: "0", hidden: "" } }),
          _vm._l(_vm.countries, function(country) {
            return _c(
              "option",
              {
                key: country.id,
                domProps: {
                  value: country.label,
                  selected:
                    country.isoCode === _vm.model || country.label === _vm.model
                }
              },
              [_vm._v(_vm._s(country.label))]
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }