var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wizard-step",
      class: { selected: _vm.isSelected || _vm.isFinished }
    },
    [
      _vm.isFinished
        ? _c("div", { staticClass: "step-index" }, [
            _c("i", { staticClass: "fa fa-check fa-fw" })
          ])
        : _c("div", { staticClass: "step-index" }, [_vm._v(_vm._s(_vm.index))]),
      _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }