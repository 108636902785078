var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "row header-container d-flex justify-content-between align-items-center"
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "col-6" }, [
        !_vm.hideSteps
          ? _c(
              "div",
              { staticClass: "steps-nav" },
              _vm._l(_vm.steps, function(step) {
                return _c("wizard-step", {
                  key: step.index,
                  staticClass: "step",
                  attrs: {
                    index: step.index,
                    "is-selected": step.index === _vm.selectedStep,
                    "is-finished": step.index < _vm.selectedStep,
                    label: step.label
                  }
                })
              }),
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "col-3 d-flex flex-row-reverse" },
        [!_vm.hideAccount ? _c("user-account-widget") : _vm._e()],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-3 d-flex align-content-center flex-wrap" },
      [
        _c("div", { staticClass: "p-2" }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("@/assets/img/bookado-logo.png") }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }