var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("breadcrumb", {
        attrs: {
          "breadcrumb-paths": [
            { name: "My Organization", path: "/dashboard" },
            { name: "Resources", path: "" }
          ]
        }
      }),
      _c(
        "div",
        { staticClass: "dashboard-panel" },
        [
          _vm._m(0),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "details-view" }, [
              _c(
                "ul",
                { staticClass: "nav nav-tabs" },
                _vm._l(_vm.resourceTypes, function(item) {
                  return _c("li", { key: item.type, staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { active: item.type === _vm.activeResourceType },
                        attrs: {
                          "data-testid": "resource-" + item.type + "-tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.onResourceTypeChanged(item.type)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "tab-content clearfix" },
                [
                  _vm._l(_vm.resourceTypes, function(item) {
                    return [
                      item.type === _vm.activeResourceType
                        ? _c(
                            "div",
                            { key: item.type, staticClass: "tab-panel" },
                            [
                              _c("subscription-resources-details-grid", {
                                attrs: {
                                  model: _vm.resources[item.type],
                                  "current-page": _vm.currentPageNumber,
                                  "resource-type": item.type,
                                  "update-labels": _vm.updateResourceTypeLabels
                                },
                                on: {
                                  refresh: function($event) {
                                    return _vm.onDataRefresh()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ])
          ]),
          _vm.isLoading
            ? _c("overlay-loader", { attrs: { "overlay-color": "#f5f6fa" } })
            : _vm._e()
        ],
        1
      ),
      _c("subscription-resources-recalculation-modal", {
        attrs: { visible: _vm.isRecalculationModalVisible }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "resources-header d-flex justify-content-between align-items-center mb-3"
      },
      [
        _c("span", { staticClass: "px-3 pt-3" }, [
          _vm._v("YOUR OFFICE 365 RESOURCES")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }