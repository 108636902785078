var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "settings-invoices-container" }, [
    _c("div", { staticClass: "left-side-container" }, [
      _c(
        "div",
        { staticClass: "toasts-container" },
        [
          _c("toast", {
            staticStyle: { "margin-bottom": "-30px!important" },
            attrs: { messages: _vm.emailAddressToastMessagesSubject }
          })
        ],
        1
      ),
      _c("label", [
        _vm._v(
          "Specify general emails for sending invoices (max. 5, separated by ';'):"
        )
      ]),
      _c(
        "div",
        { staticClass: "settings-container email-addresses-container" },
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.emailAddresses,
                expression: "emailAddresses"
              }
            ],
            attrs: {
              name: "emailAddresses",
              type: "text",
              maxlength: _vm.maxNumberOfCharactersInEmailAddresses,
              placeholder:
                "For example: invoice@domain.com; accounting@domain.com"
            },
            domProps: { value: _vm.emailAddresses },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.emailAddresses = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          !_vm.areEmailAddressesInvalid
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("characters-limit-counter", {
                      attrs: {
                        "current-model": _vm.emailAddresses,
                        "max-number-of-characters":
                          _vm.maxNumberOfCharactersInEmailAddresses
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.areEmailAddressesInvalid
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "error col-8" }, [
                  _vm._v("Valid email addresses are required")
                ]),
                _c(
                  "div",
                  { staticClass: "col-3 counter-container" },
                  [
                    _c("characters-limit-counter", {
                      attrs: {
                        "current-model": _vm.emailAddresses,
                        "max-number-of-characters":
                          _vm.maxNumberOfCharactersInEmailAddresses
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "save-button-container" }, [
            _c(
              "button",
              {
                staticClass: "primary-button small",
                on: {
                  click: function($event) {
                    return _vm.onSaveEmailAddressesClicked()
                  }
                }
              },
              [_vm._v("Save")]
            )
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "toasts-container" },
        [
          _c("toast", {
            staticStyle: {
              "margin-top": "20px",
              "margin-bottom": "-15px!important",
              height: "30px"
            },
            attrs: { messages: _vm.notesToastMessagesSubject }
          })
        ],
        1
      ),
      _c("label", [
        _vm._v(
          "Specify additional notes that you require to print on the invoice:"
        )
      ]),
      _c(
        "div",
        { staticClass: "settings-container notes-container" },
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.notes,
                expression: "notes"
              }
            ],
            attrs: {
              name: "notes",
              type: "text",
              maxlength: _vm.maxNumberOfCharactersInNotes,
              placeholder:
                "For example it might be a Po Number or a Cost Centre..."
            },
            domProps: { value: _vm.notes },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.notes = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("characters-limit-counter", {
            attrs: {
              "current-model": _vm.notes,
              "max-number-of-characters": _vm.maxNumberOfCharactersInNotes
            }
          }),
          _c("div", { staticClass: "save-button-container" }, [
            _c(
              "button",
              {
                staticClass: "primary-button small",
                on: {
                  click: function($event) {
                    return _vm.onSaveNotesClicked()
                  }
                }
              },
              [_vm._v("Save")]
            )
          ])
        ],
        1
      )
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-side-container" }, [
      _c("img", {
        staticClass: "settings-image",
        attrs: {
          src: require("@/assets/svg/settings-photo.svg"),
          alt: "settings"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }