var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "payment-action__container " +
        (_vm.isStripeErrorShowed ? "has-error" : "")
    },
    [
      _vm.isLoading
        ? _c("loader-spinner", {
            model: {
              value: _vm.loaderSpinnerData,
              callback: function($$v) {
                _vm.loaderSpinnerData = $$v
              },
              expression: "loaderSpinnerData"
            }
          })
        : _c("div", { staticClass: "stripe-error-container" }, [
            _vm.isStripeErrorShowed
              ? _c("p", { staticClass: "error" }, [
                  _vm._v(
                    "An error occurred, couldn't open new session for Stripe checkout, please try again later."
                  )
                ])
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "secondary-button credit-card-details-button",
                attrs: { "data-testid": "change-credit-card-button" },
                on: { click: _vm.submit }
              },
              [_vm._v(_vm._s(_vm.labelToShow))]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }