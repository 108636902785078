var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "description-container py-3 px-5" }, [
    _c(
      "div",
      { staticClass: "content w-100 d-flex justify-content-between" },
      [
        _c(
          "div",
          { staticClass: "description-form" },
          [
            !_vm.isNoPreviewMode
              ? _c(
                  "p",
                  {
                    class:
                      "header borderless backgroundless py-3 pl-0 " +
                      (_vm.isImagePreviewSize
                        ? "header-image-preview-size"
                        : "")
                  },
                  [_vm._v("DESCRIPTION")]
                )
              : _vm._e(),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description"
                }
              ],
              class:
                "description-input " +
                (_vm.isNoPreviewMode ? "description--no-resize" : ""),
              attrs: {
                maxlength: _vm.descriptionMaxLength,
                "data-testid": "resource-description-textarea",
                placeholder:
                  "Please specify resource description or leave empty"
              },
              domProps: { value: _vm.description },
              on: {
                keyup: _vm.onDescriptionChanged,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.description = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("characters-limit-counter", {
              attrs: {
                "current-model": _vm.description,
                "max-number-of-characters": _vm.descriptionMaxLength
              }
            }),
            !_vm.isNoPreviewMode
              ? _c("div", { staticClass: "description-buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "secondary-button mr-2",
                      attrs: {
                        "data-testid": "resource-description-cancel-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.onCancelDescriptionClicked()
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "primary-button small",
                      attrs: {
                        "data-testid": "resource-description-save-button",
                        disabled: _vm.isPristine
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSaveDescriptionClicked()
                        }
                      }
                    },
                    [_vm._v(" Save ")]
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        !_vm.isNoPreviewMode
          ? _c("resource-description-previews-list", {
              attrs: { "previews-configs": _vm.previewConfigs }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }