var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "default-image-and-description" },
    [
      _c("div", { staticClass: "edit-section" }, [
        _vm._m(0),
        _c("div", { staticClass: "edit-section__edit-panel-container" }, [
          _c("div", { staticClass: "details-view" }, [
            _c(
              "ul",
              { staticClass: "nav nav-tabs" },
              _vm._l(_vm.tabResourceTypeOptions, function(
                tabResourceTypeOption
              ) {
                return _c(
                  "li",
                  { key: tabResourceTypeOption, staticClass: "nav-item" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: {
                          active:
                            _vm.selectedResourceType === tabResourceTypeOption
                        },
                        on: {
                          click: function($event) {
                            return _vm.onTabChanged(tabResourceTypeOption)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.tabResourceTypeLabelsMap[tabResourceTypeOption]
                          )
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "toasts-container" },
              [_c("toast", { attrs: { messages: _vm.toastMessage$ } })],
              1
            ),
            !_vm.isLoading && _vm.selectedResourceImages
              ? _c("div", { staticClass: "tab-content" }, [
                  _c(
                    "div",
                    {
                      ref: "photoContainer",
                      staticClass: "tab-content__photo-container"
                    },
                    [
                      _c("resource-photo", {
                        attrs: {
                          model: _vm.selectedResourceImages,
                          "custom-image-coords": _vm.currentImageCoords,
                          "custom-preview-type": _vm.currentPreviewType,
                          "custom-image": _vm.currentOriginalImage,
                          "is-no-preview-mode": true,
                          "is-no-change-photo-thumbnail-mode": true,
                          "should-force-edit-mode": true,
                          "force-every-emit-of-cropper-change": true,
                          "is-default-image-active": !!_vm.originalCropperData
                            .customImage
                        },
                        on: {
                          imageSelected: function($event) {
                            return _vm.onImageSelected($event)
                          },
                          imageCrop: _vm.onImageCropped,
                          cropperIsReady: _vm.onCropperIsReady
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      ref: "descriptionContainer",
                      staticClass: "tab-content__description-container"
                    },
                    [
                      _c("resource-description", {
                        attrs: {
                          model: _vm.selectedResourceImages,
                          "is-no-preview-mode": true,
                          "is-image-preview-size": true,
                          "is-default-image-active": !!_vm.originalCropperData
                            .customImage
                        },
                        on: { descriptionChange: _vm.onDescriptionChanged }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              {
                class:
                  "details-view__actions " +
                  (_vm.isImageOrDescriptionChanged
                    ? ""
                    : "details-view__actions--co-content")
              },
              [
                _vm.isImageOrDescriptionChanged
                  ? [
                      _vm.isSaving || _vm.isRestoringInitialState
                        ? _c(
                            "div",
                            { staticClass: "details-view__actions__loader" },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/svg/loader.svg"),
                                  alt: "loader"
                                }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.isSaving
                                      ? "Saving changes..."
                                      : "Restoring initial state..."
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "secondary-button mr-5",
                          attrs: { disabled: _vm.areButtonsDisabled },
                          on: { click: _vm.onCancel }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "primary-button small",
                          attrs: { disabled: _vm.areButtonsDisabled },
                          on: { click: _vm.onSave }
                        },
                        [_vm._v("Save")]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "preview-section" },
        [
          _vm.previewsConfigs && _vm.previewsConfigs.length > 0
            ? _c("resource-description-previews-list", {
                attrs: {
                  "previews-configs": _vm.previewsConfigs,
                  "current-preview-type": _vm.currentPreviewType
                },
                on: {
                  previewTypeChange: function($event) {
                    return _vm.onPreviewClick($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isLoading
        ? _c("overlay-loader", { attrs: { "overlay-color": "#f5f6fa" } })
        : _vm._e(),
      _c("confirmation-modal", {
        attrs: {
          "is-visible": _vm.isConfirmationModalVisible,
          "is-discard-button-showed": true,
          heading: "You have unsaved changes",
          "primary-text":
            "Provided image and description will be used as a default for all resources of given type (" +
            _vm.tabResourceTypeLabelsMap[_vm.selectedResourceType] +
            ").",
          "secondary-text": "Please confirm to proceed.",
          "custom-confirm-button-label": "Save"
        },
        on: {
          cancel: _vm.onConfirmationDialogCancel,
          discard: _vm.onConfirmationDialogDiscard,
          confirm: _vm.onConfirmationDialogSave
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "edit-section__information" }, [
      _c("h3", [_vm._v(" Default image and description ")]),
      _c("p", [
        _vm._v(
          " Bookado allows to upload a default image and description for each resource type. "
        )
      ]),
      _c("p", [
        _vm._v(
          " If you change the default image or description, then all resources of given type that have the default image/description set will have the new image/description assigned. "
        )
      ]),
      _c("p", [
        _vm._v(
          " If you already have assigned a custom image or description for some of the resources, those resources will not be affected. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }