var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "selected-step": 4 } }),
      _c(
        "div",
        { staticClass: "dice-container" },
        [
          _c("div", { staticClass: "content-container" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("We need your authorization")
            ]),
            _c("div", { staticClass: "content" }, [
              _c("p", [
                _vm._v(
                  " To efficiently search and book resources, employees of your organization needs to know the resources location and capacity. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " In order to show this information we need your IT Administrator to follow the link and login using O365 admin account credentials, to grant Bookado access. "
                )
              ]),
              _c("ul", { staticClass: "nav nav-tabs" }, [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isAdmin, disabled: _vm.isEmailSent },
                      on: { click: _vm.toggleAdminTab }
                    },
                    [_vm._v("IT Administrator")]
                  )
                ]),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: {
                        active: !_vm.isAdmin,
                        disabled: _vm.isConsentGranted
                      },
                      on: { click: _vm.toggleNonAdminTab }
                    },
                    [_vm._v("Not an IT Administrator")]
                  )
                ])
              ]),
              _c("div", { staticClass: "tab-content clearfix" }, [
                _vm.isAdmin
                  ? _c(
                      "div",
                      {
                        staticClass: "tab-pane",
                        class: { active: _vm.isAdmin }
                      },
                      [
                        _c("p", { staticClass: "separated" }, [
                          _vm._v(
                            " If you are an IT Administrator, please click the link below and login using your admin credentials to accept Bookado accessing location and capacity fields content. "
                          )
                        ]),
                        _c("div", { staticClass: "separated consent-link" }, [
                          _c("h5", [
                            _vm._v("Authorization link for IT Administrator")
                          ]),
                          !_vm.isConsentGranted
                            ? _c("a", { attrs: { href: _vm.consentLink } }, [
                                _vm._v(_vm._s(_vm.consentLink))
                              ])
                            : _vm._e(),
                          _vm.isConsentGranted
                            ? _c("p", { staticClass: "m-0" }, [
                                _vm._v(_vm._s(_vm.consentLink))
                              ])
                            : _vm._e()
                        ]),
                        _c("h5", [_vm._v("Status")]),
                        _c("consent-status", {
                          attrs: { status: _vm.consentStatus }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isAdmin
                  ? _c(
                      "div",
                      {
                        staticClass: "tab-pane",
                        class: { active: !_vm.isAdmin }
                      },
                      [
                        _vm._m(0),
                        _c("p", { staticClass: "separated" }, [
                          _vm._v(
                            " If you don't want to do it now, you will be able to do it later from Administration Portal in Settings menu. "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "admin-form d-flex justify-content-between"
                          },
                          [
                            _c("div", { staticClass: "w-100" }, [
                              _c("label", [_vm._v("Email")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email"
                                  }
                                ],
                                attrs: {
                                  name: "email",
                                  type: "text",
                                  disabled: _vm.isSendingEmail
                                },
                                domProps: { value: _vm.email },
                                on: {
                                  keyup: _vm.onEmailChanged,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.email = $event.target.value
                                  }
                                }
                              })
                            ]),
                            _c(
                              "div",
                              { staticClass: "align-self-end pl-2 pb-1" },
                              [
                                _c("input", {
                                  staticClass: "primary-button small",
                                  attrs: {
                                    type: "button",
                                    value: "Send",
                                    disabled:
                                      !_vm.isEmailValid || _vm.isSendingEmail
                                  },
                                  on: { click: _vm.sendAdminRequest }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm.emailError
                          ? _c("div", { staticClass: "w-100" }, [
                              _c(
                                "p",
                                { staticClass: "validation-error m-0 mt-3" },
                                [_vm._v(_vm._s(_vm.emailError))]
                              )
                            ])
                          : _vm._e(),
                        _vm.showIsEmailSentMessage
                          ? _c("div", { staticClass: "w-100" }, [
                              _c("p", { staticClass: "success m-0 mt-3" }, [
                                _vm._v(" " + _vm._s(_vm.emailSentMessage) + " ")
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "back-link-button",
                    attrs: { to: { name: "step3" } }
                  },
                  [_vm._v("< Back ")]
                ),
                _c("input", {
                  staticClass: "primary-button",
                  attrs: {
                    id: "onboarding-step-4-continue",
                    type: "button",
                    value: "Continue",
                    disabled: _vm.isInDisabledStatus()
                  },
                  on: { click: _vm.saveAndProceed }
                })
              ],
              1
            )
          ]),
          _c("document-footer", { staticClass: "pt-4 pb-2" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" If you are not an IT Administrator don't worry! "),
      _c("br"),
      _vm._v(
        "Please let us know the e-mail address of an IT Administrator where we could send the link and instructions on how to proceed. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }