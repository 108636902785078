var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal-dialog-centered alter-confirmation-modal",
          attrs: { role: "document" }
        },
        [
          _vm.isCalculated
            ? _c(
                "div",
                {
                  staticClass: "modal-content px-5 py-4 dashboard-modal-content"
                },
                [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("h4", { staticClass: "modal-title" }, [
                      _vm._v("This action have impact on cost!")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "close-button",
                        on: { click: _vm.onCancel }
                      },
                      [_c("i", { staticClass: "fa fa-times fa-fw" })]
                    )
                  ]),
                  _c("div", { staticClass: "modal-body p-0 mt-4" }, [
                    _c("p", [
                      _vm._v(
                        "You want to activate " +
                          _vm._s(_vm.model.activateResourcesIds.length) +
                          " rooms from the list of resources that will be visible to Bookado users."
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "If you continue, you agree that your monthly costs will increase by " +
                          _vm._s(Math.abs(_vm.costChange)) +
                          " " +
                          _vm._s(_vm.currency) +
                          ". New monthly cost would be " +
                          _vm._s(_vm.totalCost) +
                          " " +
                          _vm._s(_vm.currency) +
                          "."
                      )
                    ])
                  ]),
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "modal-footer d-flex justify-content-start"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "secondary-button",
                          on: { click: _vm.onCancel }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "primary-button small",
                          on: { click: _vm.onConfirm }
                        },
                        [_vm._v("Confirm")]
                      )
                    ]
                  ),
                  _vm._m(1)
                ]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "costs-badge d-flex align-items-center px-4 py-2 my-3" },
      [
        _c("div", { staticClass: "icon" }, [
          _c("i", { staticClass: "fa fa-info-circle fa-fw" })
        ]),
        _c("p", { staticClass: "p-0 m-0" }, [
          _vm._v("* All costs are excl. VAT")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "theme-image-container" }, [
      _c("img", {
        staticClass: "theme-image mt-5 ml-4",
        attrs: { src: require("@/assets/svg/cost-impact.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }