var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered dashboard-modal",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-content px-5 py-4 dashboard-modal-content" },
            [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Add new team member")
              ]),
              _c(
                "div",
                { staticClass: "modal-body p-0 mt-4" },
                [
                  _c("team-member-edit-form", {
                    staticClass: "dashboard-dialog-form",
                    attrs: {
                      model: _vm.model,
                      "edit-mode": false,
                      "is-changing-primary-user-enabled": true
                    },
                    on: {
                      cancel: _vm.onCancel,
                      input: function($event) {
                        return _vm.onTeamMemberAdded($event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }