var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c("div", { staticClass: "wizard-team-member" }, [
        _c("div", { staticClass: "editor" }, [
          _c(
            "div",
            { staticClass: "editor-property" },
            [
              _c("label", [_vm._v("Name")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.name,
                    expression: "model.name"
                  }
                ],
                attrs: {
                  name: "name",
                  "data-testid": "name-input",
                  type: "text",
                  maxlength: _vm.maxNumberOfCharacters,
                  disabled: _vm.model.isPrimaryContact
                },
                domProps: { value: _vm.model.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "name", $event.target.value)
                  }
                }
              }),
              _c("characters-limit-counter", {
                attrs: {
                  "current-model": _vm.model.name,
                  "max-number-of-characters": _vm.maxNumberOfCharacters
                }
              }),
              _vm.errors.name
                ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                    _vm._v(_vm._s(_vm.errors.name))
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "editor-property" },
            [
              _c("label", [_vm._v("Surname")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.surname,
                    expression: "model.surname"
                  }
                ],
                attrs: {
                  name: "surname",
                  "data-testid": "surname-input",
                  type: "text",
                  maxlength: _vm.maxNumberOfCharacters,
                  disabled: _vm.model.isPrimaryContact
                },
                domProps: { value: _vm.model.surname },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "surname", $event.target.value)
                  }
                }
              }),
              _c("characters-limit-counter", {
                attrs: {
                  "current-model": _vm.model.surname,
                  "max-number-of-characters": _vm.maxNumberOfCharacters
                }
              }),
              _vm.errors.surname
                ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                    _vm._v(_vm._s(_vm.errors.surname))
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "editor-property" },
            [
              _c("label", [_vm._v("Email")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.email,
                    expression: "model.email"
                  }
                ],
                attrs: {
                  name: "email",
                  "data-testid": "email-input",
                  type: "text",
                  maxlength: _vm.maxNumberOfCharacters,
                  disabled: _vm.editMode
                },
                domProps: { value: _vm.model.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "email", $event.target.value)
                  }
                }
              }),
              _c("characters-limit-counter", {
                attrs: {
                  "current-model": _vm.model.email,
                  "max-number-of-characters": _vm.maxNumberOfCharacters
                }
              }),
              _vm.errors.email
                ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                    _vm._v(_vm._s(_vm.errors.email))
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "editor-property" },
            [
              _c("label", [_vm._v("Phone number")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.phoneNumber,
                    expression: "model.phoneNumber"
                  }
                ],
                attrs: {
                  name: "phone-number",
                  "data-testid": "phone-number-input",
                  type: "text",
                  maxlength: _vm.maxNumberOfCharactersInPhoneNumber
                },
                domProps: { value: _vm.model.phoneNumber },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "phoneNumber", $event.target.value)
                  }
                }
              }),
              _c("characters-limit-counter", {
                attrs: {
                  "current-model": _vm.model.phoneNumber,
                  "max-number-of-characters":
                    _vm.maxNumberOfCharactersInPhoneNumber
                }
              }),
              _vm.errors.phoneNumber
                ? _c("div", { staticClass: "error pt-2 pl-1" }, [
                    _vm._v(_vm._s(_vm.errors.phoneNumber))
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "editor-property" },
            [
              _c("checkbox", {
                attrs: {
                  model: _vm.model.isInvoicesRecipient,
                  label: "Send invoices to this contact",
                  "data-testid": "send-invoice-checkbox"
                },
                model: {
                  value: _vm.model.isInvoicesRecipient,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "isInvoicesRecipient", $$v)
                  },
                  expression: "model.isInvoicesRecipient"
                }
              })
            ],
            1
          ),
          _vm.isChangingPrimaryUserEnabled && !_vm.model.isPrimaryContact
            ? _c(
                "div",
                { staticClass: "editor-property" },
                [
                  _c("checkbox", {
                    attrs: {
                      model: _vm.setAsPrimaryUser,
                      label: "Set as primary user",
                      "data-testid": "set-as-primary-user-checkbox"
                    },
                    model: {
                      value: _vm.setAsPrimaryUser,
                      callback: function($$v) {
                        _vm.setAsPrimaryUser = $$v
                      },
                      expression: "setAsPrimaryUser"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("input", {
            staticClass: "secondary-button",
            attrs: {
              type: "button",
              "data-testid": "team-member-cancel-button",
              value: "Cancel"
            },
            on: { click: _vm.cancelForm }
          }),
          _c("input", {
            staticClass: "primary-button small",
            attrs: {
              type: "button",
              "data-testid": "team-member-save-button",
              value: "Save"
            },
            on: { click: _vm.checkForm }
          })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }