var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "consent-status", class: _vm.statusClass },
    [_vm._v(_vm._s(_vm.label))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }