var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "search-input-container" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.model,
          expression: "model"
        }
      ],
      staticClass: "search",
      attrs: {
        type: "text",
        "data-testid": "search-resource-input",
        placeholder: "Filter by name, location, attributes"
      },
      domProps: { value: _vm.model },
      on: {
        input: [
          function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.model = $event.target.value
          },
          function($event) {
            return _vm.onInput($event)
          }
        ]
      }
    }),
    _vm.model.length > 0
      ? _c("i", {
          staticClass: "fa fa-times fa-fw clean-button",
          on: { click: _vm.onClear }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }