var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toastMessages.length > 0
    ? _c(
        "div",
        { staticClass: "w-100 d-flex flex-column" },
        _vm._l(_vm.toastMessages, function(message) {
          return _c(
            "div",
            {
              key: message.id,
              staticClass: "toast-message",
              class: {
                "toast-message__success":
                  message.severity === _vm.Severity.Success,
                "toast-message__error": message.severity === _vm.Severity.Error
              },
              attrs: { "data-testid": "toast-message" }
            },
            [_vm._v(_vm._s(message.text))]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }