var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.text,
          expression: "text"
        }
      ],
      staticClass: "input-tooltip",
      attrs: { "data-toggle": "tooltip", "data-placement": "placement" }
    },
    [_vm._t("icon")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }