var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "badge",
      class: { "badge-success": _vm.model, "badge-danger": !_vm.model }
    },
    [_vm._v(_vm._s(_vm.model ? "Active" : "Inactive"))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }