var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "selected-step": 3 } }),
      _c(
        "div",
        { staticClass: "dice-container" },
        [
          _c("div", { staticClass: "content-container" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("Administration team")
            ]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("p", { staticClass: "bolded-paragraph" }, [
                  _vm._v("Primary contact details")
                ]),
                _c("wizard-team-member-editor", {
                  staticClass: "editor-control p-4",
                  attrs: { model: _vm.primaryTeamMember }
                }),
                _vm.secondaryTeamMembers.length > 0
                  ? _c("p", { staticClass: "bolded-paragraph" }, [
                      _vm._v("Secondary contacts")
                    ])
                  : _vm._e(),
                _vm._l(_vm.secondaryTeamMembers, function(member, index) {
                  return _c("wizard-team-member-editor", {
                    key: index,
                    staticClass: "editor-control p-4",
                    attrs: { "can-delete": true, model: member },
                    on: {
                      delete: function($event) {
                        return _vm.onTeamMemberDelete($event)
                      }
                    }
                  })
                }),
                _vm.isAdditionalMemberEditorVisible
                  ? [
                      _c("p", { staticClass: "bolded-paragraph" }, [
                        _vm._v("New contact")
                      ]),
                      _c("team-member-edit-form", {
                        staticClass: "editor-control p-4 mt-2",
                        attrs: {
                          model: { isPrimaryContact: false, id: 0 },
                          validate: _vm.validateNewTeamMember,
                          "is-changing-primary-user-enabled": false
                        },
                        on: {
                          cancel: _vm.onTeamMemberCancel,
                          input: function($event) {
                            return _vm.onTeamMemberAdded($event)
                          }
                        }
                      })
                    ]
                  : _vm._e(),
                _vm.secondaryTeamMembers.length < 20
                  ? [
                      _c("p", { staticClass: "bolded-paragraph" }, [
                        _vm._v("Invite team members")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "editor-control p-4 d-flex align-content-start"
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "round-button align-self-start",
                                on: { click: _vm.addTeamMember }
                              },
                              [_vm._v("+")]
                            )
                          ]),
                          _c("span", { staticClass: "ml-3" }, [
                            _vm._v(
                              "You can invite up to 20 contacts from your organization to manage this subscription"
                            )
                          ])
                        ]
                      )
                    ]
                  : _vm._e(),
                _vm.isLoading ? _c("overlay-loader") : _vm._e()
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "back-link-button",
                    attrs: { to: { name: "step2" } }
                  },
                  [_vm._v("< Back")]
                ),
                _c("input", {
                  staticClass: "primary-button",
                  attrs: {
                    id: "onboarding-step-3-continue",
                    type: "button",
                    value: "Continue",
                    disabled: !_vm.isModelValid
                  },
                  on: { click: _vm.saveAndProceed }
                })
              ],
              1
            )
          ]),
          _c("delete-member-confirmation-modal", {
            attrs: {
              visible: _vm.isDeleteTeamMemberConfirmationModalVisible,
              "confirm-button-label":
                _vm.deleteMemberConfirmationModalTitleConfirmButtonLabel,
              title: _vm.deleteMemberConfirmationModalTitle
            },
            on: {
              confirm: function($event) {
                return _vm.onDeleteConfirmed()
              },
              cancel: function($event) {
                return _vm.onDeleteCancelled()
              }
            }
          })
        ],
        1
      ),
      _c("document-footer", { staticClass: "pt-4 pb-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }