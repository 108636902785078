import { render, staticRenderFns } from "./ResourcePhoto.vue?vue&type=template&id=3025bafa&scoped=true&"
import script from "./ResourcePhoto.vue?vue&type=script&lang=ts&"
export * from "./ResourcePhoto.vue?vue&type=script&lang=ts&"
import style0 from "./ResourcePhoto.vue?vue&type=style&index=0&id=3025bafa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3025bafa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3025bafa')) {
      api.createRecord('3025bafa', component.options)
    } else {
      api.reload('3025bafa', component.options)
    }
    module.hot.accept("./ResourcePhoto.vue?vue&type=template&id=3025bafa&scoped=true&", function () {
      api.rerender('3025bafa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/resource-details/ResourcePhoto.vue"
export default component.exports