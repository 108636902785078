var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered dashboard-modal",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-content px-5 py-4 dashboard-modal-content" },
            [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Your organization details edit")
              ]),
              _c(
                "div",
                { staticClass: "modal-body p-0 mt-4" },
                [
                  _c("organization-details-form", {
                    staticClass: "dashboard-dialog-form",
                    attrs: {
                      "on-submit$": _vm.submit$,
                      "show-edit-modal-change$": _vm.showEditModalChange$,
                      "subscription-data": _vm.subscriptionData,
                      "on-validation-error": _vm.onValidationError
                    },
                    on: {
                      submitted: _vm.onFormSubmitted,
                      isLoading: _vm.onLoadingChanged
                    },
                    model: {
                      value: _vm.organizationFormValidity,
                      callback: function($$v) {
                        _vm.organizationFormValidity = $$v
                      },
                      expression: "organizationFormValidity"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "modal-footer d-flex justify-content-end" },
                [
                  _vm.isSaving
                    ? _c("div", { staticClass: "loader-container" }, [
                        _c("img", {
                          staticClass: "loader",
                          attrs: {
                            src: require("@/assets/svg/loader.svg"),
                            alt: "loader"
                          }
                        }),
                        _c("span", [_vm._v("Saving changes...")])
                      ])
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "secondary-button",
                            on: { click: _vm.onCancel }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "primary-button small",
                            attrs: {
                              disabled: _vm.organizationFormValidity.isEmpty
                            },
                            on: { click: _vm.onSave }
                          },
                          [_vm._v(" Save ")]
                        )
                      ]
                ],
                2
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }