var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "table-responsive grid-container" }, [
      _vm.model
        ? _c("table", { staticClass: "table table-borderless invoices-grid" }, [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(_vm.pageData, function(item) {
                  return [
                    _c("tr", { key: item.id }, [
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("formatDate")(item.date)))
                      ]),
                      _c("td", [_vm._v(_vm._s(item.invoiceId))]),
                      _c("td", [_vm._v(_vm._s(item.planName))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(item.costPerMonth) +
                            _vm._s(item.currency) +
                            " /month"
                        )
                      ]),
                      _c(
                        "td",
                        [
                          item.isPaid
                            ? [
                                _c(
                                  "span",
                                  { staticClass: "badge badge-success" },
                                  [_vm._v("PAID")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "payment-date ml-2" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(item.paymentDate)
                                      )
                                    )
                                  ]
                                )
                              ]
                            : [
                                _c(
                                  "span",
                                  { staticClass: "badge badge-danger" },
                                  [_vm._v("NOT PAID")]
                                )
                              ]
                        ],
                        2
                      ),
                      _c("td", [
                        _c(
                          "a",
                          {
                            staticClass: "invoice-action mr-5",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.downloadInvoice($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-fw fa-download mr-2"
                            }),
                            _vm._v("Download ")
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "invoice-action",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.showInvoiceDetails($event, item)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-fw fa-eye mr-2" }),
                            _vm._v("See details ")
                          ]
                        )
                      ])
                    ])
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-4" },
        [_c("grid-pager-component", { attrs: { parent: this } })],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-calendar fa-fw mr-2" }),
          _vm._v(" DATE ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-eye fa-fw mr-2" }),
          _vm._v(" INVOICE ID ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-tag fa-fw mr-2" }),
          _vm._v(" PLAN ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-money fa-fw mr-2" }),
          _vm._v(" COST ")
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c("i", { staticClass: "fa fa-user fa-fw mr-2" }),
          _vm._v(" PAYMENT STATUS ")
        ]),
        _c("th", { attrs: { scope: "col", colspan: "2" } }, [
          _c("i", { staticClass: "fa fa-edit fa-fw mr-2" }),
          _vm._v(" ACTIONS ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }