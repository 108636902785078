var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid home" },
    [
      _c("wizard-header", { attrs: { "hide-steps": true } }),
      _c("div", { staticClass: "dice-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "title" }, [_vm._v("Welcome to Bookado!")]),
          _c("div", { staticClass: "content" }, [
            _c("p", [
              _vm._v(
                "On next pages you will be able to manage your Bookado resources."
              )
            ]),
            _c("ul", { staticClass: "consents-list" }, [
              _c(
                "li",
                [
                  _c("checkbox", {
                    attrs: {
                      model: _vm.adminConsent,
                      label: "I confirm I can act on behalf of my organization",
                      "is-required": true
                    },
                    model: {
                      value: _vm.adminConsent,
                      callback: function($$v) {
                        _vm.adminConsent = $$v
                      },
                      expression: "adminConsent"
                    }
                  })
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("checkbox", {
                    attrs: {
                      model: _vm.termsConsent,
                      label: "I confirm I have read and accepted",
                      "is-required": true
                    },
                    model: {
                      value: _vm.termsConsent,
                      callback: function($$v) {
                        _vm.termsConsent = $$v
                      },
                      expression: "termsConsent"
                    }
                  }),
                  _c("div", { staticClass: "consent-documents" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.termsAndConditionsLink,
                          target: "_blank"
                        }
                      },
                      [_vm._v("Terms of use")]
                    ),
                    _vm._v(" and "),
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.privacyPolicyLink, target: "_blank" }
                      },
                      [_vm._v("Privacy Policy")]
                    )
                  ])
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _vm.isLoggedIn
                ? [
                    _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                      _c(
                        "button",
                        {
                          staticClass: "primary-button",
                          attrs: { disabled: !_vm.isConsentGranted },
                          on: { click: _vm.onContinue }
                        },
                        [_vm._v("Continue")]
                      )
                    ])
                  ]
                : [
                    _c("p", { staticClass: "bolded-paragraph" }, [
                      _vm._v("Sign in using your company credentials")
                    ]),
                    _c(
                      "div",
                      { staticClass: "login-vendors" },
                      [
                        _c(
                          "login-button",
                          {
                            attrs: {
                              label: "Sign in with Microsoft",
                              enabled: _vm.isConsentGranted
                            },
                            on: { click: _vm.loginMicrosoft }
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                slot: "icon",
                                src: require("@/assets/img/ms-logo.png")
                              },
                              slot: "icon"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ])
      ]),
      _c("document-footer", { staticClass: "pt-4 pb-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }