var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid dashboard-container" },
    [
      _c(
        "div",
        { staticClass: "row min-vh-100 flex-column flex-md-row flex-nowrap" },
        [
          _c(
            "aside",
            {
              staticClass:
                "col-12 flex-shrink-1 menu-container d-flex align-items-start flex-column p-0"
            },
            [
              _c(
                "nav",
                {
                  staticClass:
                    "navbar p-0 w-100 navbar-expand navbar-dark mb-auto"
                },
                [
                  _c("div", { staticClass: "collapse navbar-collapse w-100" }, [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "flex-md-column flex-row navbar-nav w-100 justify-content-between pt-5"
                      },
                      [
                        _vm._m(0),
                        _vm._l(_vm.menuItems, function(menuItem) {
                          return [
                            _c(
                              "li",
                              {
                                directives: [
                                  {
                                    name: "feature-enabled",
                                    rawName: "v-feature-enabled",
                                    value: menuItem.featureEnabledName,
                                    expression: "menuItem.featureEnabledName"
                                  }
                                ],
                                key: menuItem.index,
                                staticClass: "nav-item",
                                class: {
                                  active:
                                    _vm.activeMenuItemIndex === menuItem.index
                                },
                                attrs: {
                                  "data-testid": menuItem.label
                                    .replaceAll(" ", "-")
                                    .toLowerCase()
                                }
                              },
                              [
                                menuItem.submenuItems.length > 0 ||
                                !menuItem.link
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "nav-link",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.onMenuItemClick(
                                              $event,
                                              menuItem
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "fa-icon",
                                          attrs: { icon: menuItem.iconClass }
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(menuItem.label))
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "menu-toggler" },
                                          [
                                            _vm.expandedMenuItemIndex ===
                                            menuItem.index
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-chevron-up fa-fw menu-toggler-icon"
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "fa fa-chevron-down fa-fw menu-toggler-icon"
                                                })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "router-link",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          hidden: menuItem.isHidden,
                                          to: { name: menuItem.link }
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.onMenuItemClick(
                                              $event,
                                              menuItem
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "fa-icon",
                                          attrs: { icon: menuItem.iconClass }
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(menuItem.label))
                                        ])
                                      ],
                                      1
                                    )
                              ],
                              1
                            ),
                            menuItem.submenuItems.length > 0
                              ? _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "flex-md-column flex-row navbar-nav w-100 justify-content-between submenu",
                                    class: {
                                      expanded:
                                        _vm.expandedMenuItemIndex ===
                                        menuItem.index
                                    }
                                  },
                                  _vm._l(menuItem.submenuItems, function(
                                    submenuItem
                                  ) {
                                    return _c(
                                      "li",
                                      {
                                        directives: [
                                          {
                                            name: "feature-enabled",
                                            rawName: "v-feature-enabled",
                                            value:
                                              submenuItem.featureEnabledName,
                                            expression:
                                              "submenuItem.featureEnabledName"
                                          }
                                        ],
                                        key: submenuItem.index,
                                        staticClass: "nav-item ml-5",
                                        attrs: { hidden: submenuItem.isHidden }
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link submenu-link",
                                            class: {
                                              "router-link-exact-active": submenuItem.exactActiveToCheck
                                                ? _vm.subIsActive(
                                                    submenuItem.exactActiveToCheck
                                                  )
                                                : false
                                            },
                                            attrs: {
                                              "data-testid": submenuItem.label
                                                .replaceAll(" ", "-")
                                                .toLowerCase(),
                                              to: { name: submenuItem.link }
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.onSubmenuItemClick(
                                                  menuItem.index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "fa-icon",
                                              attrs: {
                                                icon: submenuItem.iconClass
                                              }
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(submenuItem.label))
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ]
              ),
              _vm._m(1),
              _c(
                "div",
                { staticClass: "w-100 text-center mt-4 mb-2" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "primary-button",
                      on: { click: _vm.onSupportClick }
                    },
                    [_vm._v("Support")]
                  ),
                  _c("document-footer", { staticClass: "pt-3 pb-2" })
                ],
                1
              )
            ]
          ),
          _c(
            "main",
            { staticClass: "col bg-faded content-container flex-grow-1" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header d-flex justify-content-between align-items-center"
                },
                [
                  _c("h2", { staticClass: "px-5" }, [_vm._v("Dashboard")]),
                  _c("user-account-widget", {
                    staticClass: "h-100 user-account-widget"
                  })
                ],
                1
              ),
              _c("div", { staticClass: "content" }, [_c("router-view")], 1)
            ]
          )
        ]
      ),
      _c("confirmation-modal", {
        attrs: {
          "is-visible": _vm.isCardPaymentConfirmationModalVisible,
          heading: "Dear Customer",
          "primary-text":
            "We are happy to inform you that since now the Credit Card subscription payments are available.\n" +
            "You can manage your payment method and add your credit card in <u>My organization</u> / <u>Subscription</u> by pressing the “Manage” button.\n" +
            "If you do not see the button - please refresh your browser’s cache using CTRL+SHIFT+R, the button should appear then.",
          "secondary-text":
            "If you would like to add your card now, please use “Setup now” button, or press Cancel to do it later.\n" +
            "\n" +
            "As always, in case of questions and problems please contact us at <a href='mailto:support@bookado.io'>support@bookado.io</a>.",
          "custom-confirm-button-label": "Setup now"
        },
        on: {
          cancel: _vm.onCardPaymentConfirmationDialogCancel,
          confirm: _vm.onCardPaymentConfirmationDialogSave
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c("a", { staticClass: "nav-link text-nowrap", attrs: { href: "#" } }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("@/assets/img/bookado-logo.png"),
            alt: "bookado logo"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-100 text-center" }, [
      _c("img", {
        staticClass: "menu-image",
        attrs: {
          src: require("@/assets/svg/dashboard-menu.svg"),
          alt: "menu image"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }