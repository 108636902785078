var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal-dialog-centered dashboard-modal markers-modal",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-content px-5 py-4 dashboard-modal-content" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between markers-modal-header"
                },
                [
                  _vm.isGenerated
                    ? _c("h4", { staticClass: "modal-title" }, [
                        _vm._v("Success!")
                      ])
                    : _vm._e(),
                  !_vm.isGenerated
                    ? _c("h4", { staticClass: "modal-title" }, [
                        _vm._v("Generating markers")
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "close-button", on: { click: _vm.onClose } },
                    [_c("i", { staticClass: "fa fa-times fa-fw" })]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body markers-modal-body p-0 mt-4" },
                [
                  !_vm.isGenerated
                    ? [_vm._m(0)]
                    : [
                        _c("div", { staticClass: "description" }, [
                          _vm.resourceType === _vm.ResourceType.Room
                            ? _c("p", [
                                _vm._v(
                                  "Great! Markers for selected rooms have been generated"
                                )
                              ])
                            : _vm._e(),
                          _vm.resourceType === _vm.ResourceType.Desk
                            ? _c("p", [
                                _vm._v(
                                  "Great! Markers for selected desks have been generated"
                                )
                              ])
                            : _vm._e(),
                          _vm._m(1)
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "markers-file-badge d-flex align-items-center px-4 py-3"
                          },
                          [
                            _vm._m(2),
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("p", { staticClass: "title" }, [
                                _vm._v(_vm._s(_vm.file.name))
                              ]),
                              _c("p", { staticClass: "weight" }, [
                                _vm._v(_vm._s(_vm.file.weight))
                              ])
                            ])
                          ]
                        )
                      ]
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex justify-content-start px-0 mt-3"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "primary-button",
                      attrs: {
                        disabled: !_vm.isGenerated,
                        "data-testid": "download-markers-button"
                      },
                      on: { click: _vm.onDownload }
                    },
                    [
                      !_vm.isGenerated
                        ? _c("img", {
                            staticClass: "loading-icon pb-1 mr-2",
                            attrs: { src: require("@/assets/svg/loader.svg") }
                          })
                        : _vm._e(),
                      _vm._v(" Download ")
                    ]
                  )
                ]
              ),
              _vm._m(3)
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description d-flex align-items-center" }, [
      _c("p", { staticClass: "m-0 align-self-center" }, [
        _vm._v("Please wait as we're generating your markers...")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "py-3" }, [
      _vm._v(" Download your .pdf file and print your markers. "),
      _c("br"),
      _vm._v("Thanks! ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-file fa-fw" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "theme-image-container" }, [
      _c("img", {
        staticClass: "theme-image",
        attrs: { src: require("@/assets/svg/markers-generated.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }