var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal overflow-modal",
      class: { "modal--visible": _vm.visible },
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "modal-dialog modal-dialog-centered resources-recalculation-modal",
        attrs: { role: "document" }
      },
      [
        _c(
          "div",
          { staticClass: "modal-content px-5 py-4 dashboard-modal-content" },
          [
            _c("h4", { staticClass: "modal-title" }, [_vm._v("Please wait")]),
            _c("div", { staticClass: "modal-body p-0 mt-4" }, [
              _c("p", [
                _vm._v(
                  "We're recalculating your resources. This may take some time."
                )
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }