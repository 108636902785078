var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c("breadcrumb", {
        attrs: {
          "breadcrumb-paths": [
            { name: "My Organization", path: "/dashboard" },
            { name: "Organization", path: "" }
          ]
        }
      }),
      _c(
        "div",
        { staticClass: "dashboard-panel" },
        [
          _c("div", { staticClass: "header" }, [
            _vm._v("Your organization details")
          ]),
          _c("div", { staticClass: "content d-flex justify-content-between" }, [
            _c(
              "div",
              {
                staticClass: "details-view",
                attrs: { "data-testid": "organization-details-container" }
              },
              [
                _vm._l(_vm.properties, function(property) {
                  return _c(
                    "div",
                    {
                      key: property.key,
                      staticClass: "d-flex p-3 align-items-start"
                    },
                    [
                      _c("i", { staticClass: "fa fa-fw fa-bookmark" }),
                      _c(
                        "div",
                        {
                          staticClass: "property-details pl-4",
                          attrs: { "data-testid": property.key }
                        },
                        [
                          _c("label", { staticClass: "label" }, [
                            _vm._v(_vm._s(property.label))
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(property.value))
                          ])
                        ]
                      )
                    ]
                  )
                }),
                _c("div", { staticClass: "splitter" }),
                _c("div", { staticClass: "px-2 pt-4 pb-2" }, [
                  _c("input", {
                    staticClass: "secondary-button",
                    attrs: {
                      type: "button",
                      value: "Manage",
                      "data-testid": "manage-organization-button"
                    },
                    on: { click: _vm.showEditModal }
                  })
                ])
              ],
              2
            ),
            _c("img", {
              staticClass: "theme-image align-self-center",
              attrs: {
                src: require("@/assets/svg/organization-details.svg"),
                alt: "organization details"
              }
            })
          ]),
          _vm.isLoading
            ? _c("overlay-loader", { attrs: { "overlay-color": "#f5f6fa" } })
            : _vm._e()
        ],
        1
      ),
      !_vm.isLoadingSubscriptionData && !!_vm.subscriptionData
        ? _c("organization-details-edit-modal", {
            attrs: {
              visible: _vm.isEditModalVisible,
              "show-edit-modal-change$": _vm.showEditModalChange$,
              "subscription-data": _vm.subscriptionData
            },
            on: { cancel: _vm.onEditModalCancel, accept: _vm.onEditModalAccept }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }