var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.previewsConfigs
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-column preview-list-container",
          attrs: { "data-testid": "photo-preview-container" }
        },
        [
          _c(
            "h5",
            { staticClass: "header borderless backgroundless py-3 pl-0" },
            [_vm._v("PREVIEW")]
          ),
          _vm._m(0),
          _vm.isLoadingPreview
            ? _c("overlay-loader", { attrs: { "overlay-color": "#fff" } })
            : _vm._e(),
          !!_vm.previewsConfigs && !_vm.isLoadingPreview
            ? _c(
                "div",
                { staticClass: "d-flex justify-content-between w-100" },
                _vm._l(_vm.previewsConfigs, function(config) {
                  return _c("resource-photo-preview", {
                    key: config.type,
                    attrs: {
                      config: config,
                      "is-active": config.type === _vm.currentPreviewType
                    },
                    on: {
                      click: function($event) {
                        return _vm.onPreviewClick(config.type)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "preview-notes" }, [
      _vm._v(
        " Click on preview screens to adjust the photo to resource information screens in the mobile application. "
      ),
      _c("br"),
      _vm._v(
        " Note: changes may take up to 1 hour to synchronize with mobile applications. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }