var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-card d-flex align-items-center" }, [
    _c("div", { staticClass: "initials mt-1 mb-1" }, [
      _vm._v(_vm._s(_vm.initials))
    ]),
    _vm.user
      ? _c(
          "div",
          {
            staticClass: "details pl-3",
            style: {
              width: !!_vm.detailsWidth ? _vm.detailsWidth + "px" : "auto"
            }
          },
          [
            _c("span", { staticClass: "user-name" }, [
              _vm._v(_vm._s(_vm.user.name) + " " + _vm._s(_vm.user.surname))
            ]),
            _c("span", { staticClass: "user-email" }, [
              _vm._v(_vm._s(_vm.user.email))
            ]),
            _vm.showPhoneNumber && _vm.user.phoneNumber
              ? _c("span", { staticClass: "user-phone" }, [
                  _vm._v(_vm._s(_vm.user.phoneNumber))
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }