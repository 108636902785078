var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "resources-actions d-flex justify-content-between" },
        [
          _c("div", { staticClass: "action-buttons" }, [
            _c("i", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: {
                    placement: "bottom",
                    classes: ["wide-tooltip"],
                    offset: 15,
                    trigger: "click",
                    content:
                      "<p>Select resources from the list below and:<ul><li>press button 'Show' to show them in Bookado Mobile and Bookado for Teams. This may have impact on your billing.</li><li>press button 'Hide' to hide them in Bookado Mobile and Bookado for Teams. This may have impact on your billing.</li><li>press button 'Generate markers' to generate Augmented Reality markers in pdf format to print. Those markers scanned by Bookado Mobile app allows for fast reservations.</li></ul></p><p>Button 'Refresh data' allows for manual synchronization between your Office 365 and Bookado. Note that some changes (like resource names, capacity, locations) are propagated by Office 365 up to 24h after change. Please be patient.</p>",
                    html: true
                  },
                  expression:
                    "{ placement: 'bottom', classes: ['wide-tooltip'], offset: 15, trigger: 'click', content: '<p>Select resources from the list below and:<ul><li>press button \\'Show\\' to show them in Bookado Mobile and Bookado for Teams. This may have impact on your billing.</li><li>press button \\'Hide\\' to hide them in Bookado Mobile and Bookado for Teams. This may have impact on your billing.</li><li>press button \\'Generate markers\\' to generate Augmented Reality markers in pdf format to print. Those markers scanned by Bookado Mobile app allows for fast reservations.</li></ul></p><p>Button \\'Refresh data\\' allows for manual synchronization between your Office 365 and Bookado. Note that some changes (like resource names, capacity, locations) are propagated by Office 365 up to 24h after change. Please be patient.</p>', html: true }"
                }
              ],
              staticClass: "fa fa-info-circle fa-fw mr-2 tooltip-icon",
              attrs: { slot: "icon", "data-testid": "tooltip-button" },
              slot: "icon"
            }),
            _c(
              "div",
              {
                staticClass: "action-button mr-2",
                class: { disabled: !_vm.isAnyHiddenRowSelected },
                attrs: { "data-testid": "show-resource-button" },
                on: { click: _vm.onActivateResources }
              },
              [
                _c("i", { staticClass: "fa fa-eye fa-fw" }),
                _c("span", { staticClass: "label" }, [_vm._v("Show")])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "action-button mr-2",
                class: { disabled: !_vm.isAnyVisibleRowSelected },
                attrs: { "data-testid": "hide-resource-button" },
                on: { click: _vm.onDeactivateResources }
              },
              [
                _c("i", { staticClass: "fa fa-eye fa-fw" }),
                _c("span", { staticClass: "label" }, [_vm._v("Hide")])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "action-button",
                class: { disabled: !_vm.isAnyDataSourceSelected },
                attrs: { "data-testid": "generate-markers-button" },
                on: { click: _vm.onGenerateResourcesMarkers }
              },
              [
                _c("i", { staticClass: "fa fa-qrcode fa-fw" }),
                _c("span", { staticClass: "label" }, [
                  _vm._v("Generate markers")
                ])
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "data-actions d-flex justify-content-between" },
            [
              _c("search-input", {
                staticClass: "search-input",
                on: {
                  input: function($event) {
                    return _vm.onGridSearch($event)
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass: "primary-button small refresh-button ml-3",
                  attrs: { "data-testid": "refresh-data-button" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("refresh")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-refresh fa-fw" }),
                  _vm._v(" Refresh data ")
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "table-responsive grid-container",
          attrs: { "data-testid": "resource-list-container" }
        },
        [
          _vm.model
            ? _c(
                "table",
                {
                  staticClass:
                    "table table-borderless subscription-resources-details-grid"
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        [
                          _c("checkbox", {
                            attrs: { model: _vm.isAllRowsSelected },
                            on: {
                              input: function($event) {
                                return _vm.toggleSelectAll($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._m(0),
                      _vm._m(1),
                      _vm._m(2),
                      _vm._m(3),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("MORE DETAILS")
                      ])
                    ])
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.pageData, function(resource) {
                        return [
                          _c(
                            "tr",
                            {
                              key: resource.id,
                              attrs: {
                                "data-testid":
                                  "resource-" + resource.name + "-row"
                              }
                            },
                            [
                              _c(
                                "td",
                                [
                                  _c("checkbox", {
                                    attrs: {
                                      "data-testid": "resource-row-checkbox",
                                      model: resource.isSelected
                                    },
                                    model: {
                                      value: resource.isSelected,
                                      callback: function($$v) {
                                        _vm.$set(resource, "isSelected", $$v)
                                      },
                                      expression: "resource.isSelected"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("td", [
                                _c("i", {
                                  staticClass:
                                    "fa fa-eye fa-fw mr-2 fa-eye-icon",
                                  class: { "fa-disabled": !resource.visible },
                                  attrs: {
                                    "data-testid": "resource-visibility-eye"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onVisibilityChange(
                                        resource.id,
                                        !resource.visible
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("td", [_vm._v(_vm._s(resource.name))]),
                              _c("td", [_vm._v(_vm._s(resource.location))]),
                              _c("td", [_vm._v(_vm._s(resource.capacity))]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "primary-button small",
                                    attrs: {
                                      "data-testid":
                                        "resource-" +
                                        resource.name +
                                        "-details-button"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onResourceDetailsClicked(
                                          resource
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Details")]
                                )
                              ])
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ]
              )
            : _vm._e(),
          _vm.thisRef
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("grid-pager-component", { attrs: { parent: _vm.thisRef } })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("activate-resources-confirmation-modal", {
        attrs: {
          visible: _vm.isActivateResourcesModalVisible,
          model: _vm.alterRequest
        },
        on: {
          cancel: _vm.onResourcesModalClosed,
          confirm: _vm.onAlterResourcesModalAccept
        }
      }),
      _c("deactivate-resources-confirmation-modal", {
        attrs: {
          visible: _vm.isDeactivateResourcesModalVisible,
          model: _vm.alterRequest
        },
        on: {
          cancel: _vm.onResourcesModalClosed,
          confirm: _vm.onAlterResourcesModalAccept
        }
      }),
      _c("resources-markers-modal", {
        attrs: {
          visible: _vm.isMarkersModalVisible,
          resources: _vm.markersResources,
          "resource-type": _vm.resourceType
        },
        on: { close: _vm.onResourcesMarkersModalClosed }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "col" } }, [
      _c("i", { staticClass: "fa fa-eye fa-fw mr-2" }),
      _vm._v(" VISIBLE ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "col" } }, [
      _c("i", { staticClass: "fa fa-room fa-fw mr-2" }),
      _vm._v(" RESOURCE NAME ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "col" } }, [
      _c("i", { staticClass: "fa fa-map-marker fa-fw mr-2" }),
      _vm._v(" LOCATION ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "col" } }, [
      _c("i", { staticClass: "fa fa-user fa-fw mr-2" }),
      _vm._v(" RESOURCE CAPACITY ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }