var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cropper-container" },
    [
      _vm.readonly && !_vm.isLoaderVisible
        ? _c("p", { staticClass: "cropper-container__readonly-label" }, [
            _vm._v(" Default image. For adjustment go to "),
            _c(
              "a",
              {
                attrs: {
                  href:
                    "/dashboard/settings/resources-defaults?previewType=" +
                    _vm.resourceType
                }
              },
              [_vm._v("Default Image Settings")]
            ),
            _vm._v(". ")
          ])
        : _vm._e(),
      _c("cropper", {
        ref: "cropper",
        staticClass: "cropper",
        attrs: {
          src: _vm.image,
          "stencil-props": {
            aspectRatio: _vm.aspectRatio,
            lines: _vm.stencilLines,
            handlers: _vm.stencilHandlers,
            movable: !_vm.readonly,
            resizable: !_vm.readonly
          },
          "auto-zoom": "false",
          "boundaries-class": _vm.isLoaderVisible
            ? "hidden-boundaries"
            : "normal-boundaries",
          "resize-image": false,
          "default-boundaries": "fill"
        },
        on: { change: _vm.cropperChange, ready: _vm.onCropperIsReady }
      }),
      _vm.isLoaderVisible
        ? _c("overlay-loader", {
            attrs: { "overlay-color": "rgb(0, 0, 0, 0)" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }