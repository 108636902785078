import { render, staticRenderFns } from "./SetAsDefaultPhotoConfirmationModal.vue?vue&type=template&id=4421cdbe&scoped=true&"
import script from "./SetAsDefaultPhotoConfirmationModal.vue?vue&type=script&lang=ts&"
export * from "./SetAsDefaultPhotoConfirmationModal.vue?vue&type=script&lang=ts&"
import style0 from "./SetAsDefaultPhotoConfirmationModal.vue?vue&type=style&index=0&id=4421cdbe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4421cdbe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4421cdbe')) {
      api.createRecord('4421cdbe', component.options)
    } else {
      api.reload('4421cdbe', component.options)
    }
    module.hot.accept("./SetAsDefaultPhotoConfirmationModal.vue?vue&type=template&id=4421cdbe&scoped=true&", function () {
      api.rerender('4421cdbe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/SetAsDefaultPhotoConfirmationModal.vue"
export default component.exports