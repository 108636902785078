// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/poppins-regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/poppins-semibold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/OpenSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/fonts/OpenSans-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@/assets/fonts/OpenSans-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Poppins-Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Poppins-SemiBold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"OpenSans-Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"OpenSans-SemiBold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"OpenSans-Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");\n}\n.check-icon[data-v-cffda8f8] {\n  color: #5ABAA2;\n  font-size: 1.2em;\n  height: 100%;\n}\n.check-icon-regular[data-v-cffda8f8] {\n  color: #5ABAA2;\n  font-size: 2.4rem;\n  height: 100%;\n  margin: auto;\n}\n.cross-icon[data-v-cffda8f8] {\n  color: #FF5F7A;\n  font-size: 1.2em;\n  height: 100%;\n}\n.cross-icon-regular[data-v-cffda8f8] {\n  color: #FF5F7A;\n  font-size: 2.4rem;\n  height: 100%;\n  margin: auto;\n}\n.warning-icon[data-v-cffda8f8] {\n  color: #FFA65F;\n  font-size: 1.2em;\n  height: 100%;\n}\n.back-icon[data-v-cffda8f8] {\n  color: rgb(94, 94, 94);\n  font-size: 0.8em;\n  height: 100%;\n}\n.unselectable[data-v-cffda8f8] {\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  user-select: none;\n}\n.back-button-container[data-v-cffda8f8] {\n  cursor: pointer;\n  max-width: 18px;\n}", ""]);
// Exports
module.exports = exports;
